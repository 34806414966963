<style
  lang="postcss"
  scoped
>
  .content {
    box-shadow: 0 0 8px 1px #ddd;

    & .flex-1 {
      position: relative;
      padding: 0.05rem;
      color: #777;

      &.color-2 {
        color: #a69ead;
      }

      & img {
        width: 0.28rem;
        margin: 0 auto 0.02rem;
      }

      & .img {
        display: block;
      }

      & .img2 {
        display: none;
      }

      &.active {
        color: var(--base-color);

        &.color-2 {
          font-weight: bold;
        }

        & .img {
          display: none;
        }

        & .img2 {
          display: block;
        }
      }
    }

    & span {
      display: block;
      line-height: 1;
      font-size: 0.09rem;
    }

    & .iconfont {
      font-size: 0.24rem;
      display: block;
    }

    & .flag {
      position: absolute;
      top: -5%;
      left: 55%;
      transform-origin: center center;
      background: var(--base-color);
    }
  }
</style>
<template>
  <div class="tab-view">
    <router-view/>
    <my-footer
      :id="id"
      class="flex-between content"
      :class="{'hidden-module': !visible}"
      v-if="footerVisible"
      data-style-name="box"
      :style="formatStyle(userStyle.box)"
    >
      <template slot="content">
        <template v-for="val in list">
          <my-link
            :link="deal(val.link)"
            class="flex-1"
            :class="{active: selected === val.id, 'color-2': $root['theme'] === 2}"
            :key="val.id"
            data-style-name="item"
            :style="formatStyle(userStyle.item)"
          >
            <div v-if="val.img && val.img2">
              <img
                :src="val.img"
                class="img"
                :alt="val.name"
                data-style-name="image"
                :style="formatStyle(userStyle.image)"
              />
              <img
                :src="val.img2"
                class="img2"
                :alt="val.name"
                :style="formatStyle(userStyle.image)"
              />
            </div>
            <template v-else>
              <span
                data-style-name="icon"
                :style="formatStyle(userStyle.icon)"
                :class="['iconfont',(val.icon+(selected===val.id?'-fill':''))]"
                v-if="$root['theme'] !== 2"
              ></span>
              <svg
                class="iconsvg"
                aria-hidden="true"
                v-else
              >
                <use :xlink:href="translateIcon(val.icon)"></use>
              </svg>
            </template>
            <span
              data-style-name="text"
              :style="formatStyle(userStyle.text)"
            >{{ val.name }}</span>
            <mt-badge
              size="small"
              class="flag"
              v-if="val.icon === 'icon-cart' && cart_num"
            >{{ cart_num }}
            </mt-badge>
          </my-link>
        </template>
      </template>
    </my-footer>
  </div>
</template>
<script>
  import { Badge } from "mint-ui"
  import Footer from "../components/Footer.vue"
  import Link from "../components/Link"
  import { mapActions, mapGetters } from "vuex"

  const { modules = [] } = window._config
  const { visible, list, id, style: userStyle = {} } = modules.filter(val => val.code === "footer")[0] || {}

  export default {
    name: "TabView",
    data () {
      return {
        selected: 0,
        visible,
        list,
        store: "",
        id,
        userStyle
      }
    },
    computed: {
      ...mapGetters({
        cart_num: "cartTotal"
      }),
      footerVisible () {
        if (!this.id) {
          return false
        }
        return this.$root.preview.inIframe ? true : this.visible
      }
    },
    components: {
      [Badge.name]: Badge,
      [Footer.name]: Footer,
      [Link.name]: Link
    },
    watch: {
      // 当前选中的tab
      "$route" (curr) {
        this.tabActive(curr.path)
      }
    },
    methods: {
      tabActive (path) {
        try {
          if (path === "/index.html") {
            path = "/"
          }
          const curr = this.list.filter(val => {
            if (val.link.path) {
              return val.link.path === path
            } else {
              const routePath = typeof val.link.query === "string" ? val.link.query : val.link.query.path
              return routePath.split('?')[0] === path
            }
          })[0] || {}
          this.selected = curr.id
        } catch (e) {}
      },
      ...mapActions({
        cartNum: "cartNumAsync"
      }),
      deal (val) {
        if (["application", "site"].includes(val.type)) {
          if (!this.store) {
            return val
          }
          return {
            ...val,
            params: {
              store: this.store
            }
          }
        }
        // 自定义分类
        if (val.type === "custom") {
          if (typeof val.query === "object") {
            return {
              ...val,
              query: {
                ...val.query,
                query: {
                  ...val.query.query,
                  store: this.store
                }
              }
            }
          }
        }
        // 分类特殊处理 存在 总分类 和 店铺分类
        if (val && val.path === "/menu") {
          if (this.store) {
            if (!val.query) {
              val.query = {}
            }
            val.query.store = this.store
          }
        }
        return val
      },
      translateIcon (iconName) {
        switch (iconName) {
          case "icon-homepage":
            return "#icon-Home"
          case "icon-createtask":
            return "#icon-Category"
          case "icon-cart":
            return "#icon-Bag"
          case "icon-order":
            return "#icon-Document"
          case "icon-people":
            return "#icon-Profile"
          default:
            return ""
        }
      }
    },
    // footer tab active
    beforeRouteEnter (to, from, next) {
      next(vm => {
        vm.tabActive(to.path)
        // 购物车商品数
        if (to.name !== "cart") {
          vm.cartNum()
        }
      })
    },
    created () {
      window.eventBus.$on("footerChange", (val, store) => {
        if (val) {
          this.list = val.list
          this.visible = val.visible
          this.id = val.id
          if (val.style) {
            this.userStyle = val.style
          }
        } else {
          this.visible = false
          this.list = []
          this.id = undefined
          this.userStyle = {}
        }
        this.store = store || "" // 店铺分类
        this.tabActive(this.$route.path)
      })
    }
  }
</script>
