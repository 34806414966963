<style
  lang="postcss"
  scoped
>
  .addr-info {
    overflow: hidden;

    & .user {
      margin: 0.04rem 0;
    }

    & .name {
      font-size: 0.16rem;
      font-weight: bold;
      margin-right: 0.1rem;
    }

    & .phone {
      font-weight: bold;
      font-family: NUM_Regular;
      font-size: 0.14rem;
    }

    & .default {
      background: var(--base-color);
      color: #fff;
      border-radius: 0.1rem;
      padding: 0 0.04rem;
      font-size: 0.08rem;
      line-height: 1.6;
      margin-left: 0.05rem;
    }

    & .street {
      font-size: 0.11rem;
      font-weight: lighter;
      line-height: 1.2;
      margin: 0.04rem 0;

      & .iconfont {
        font-size: 0.12rem;
        color: var(--base-color);
      }
    }
  }
</style>
<template>
  <div class="addr-info">
    <div class="flex-left-center user">
      <span class="name">{{ info.true_name | hide }}</span>
      <span
        class="phone"
        v-if="info.true_name !== info.mob_phone"
      >{{ info.mob_phone | hide }}</span>
      <span
        class="default"
        v-if="info.is_default === '1'"
      >默认</span>
    </div>
    <div
      class="street"
      :class='{"text-1": !showAll}'
    >
      <span class="iconfont icon-location"></span>
      <template v-if="info.address_config">
        <strong>{{info.address_config?.name}}{{info.address_config.houseNumber}} </strong>
        <span class="text-1">{{info.address_config.address}}</span>
      </template>
      <template v-else>
        {{ info.area_info }}{{ info.address === info.area_info ? "" : info.address }}
      </template>
    </div>
  </div>
</template>
<script>
  export default {
    name: "my-address",
    props: {
      info: {
        type: Object,
        required: true
      },
      showAll: {
        type: Boolean,
        default: false
      }
    },
    filters: {
      hide (str) {
        if (/^1[3456789]\d{9}$/g.test(str)) {
          return str.slice(0, 3) + "****" + str.slice(-4)
        }
        return str
      }
    }
  }
</script>
