<style lang="postcss">
  .native-header {
    background: #fff !important;
    color: #333 !important;
    z-index: 99999 !important;
    padding-top: env(safe-area-inset-top);
    height: calc(env(safe-area-inset-top) + 0.47rem) !important;
    font-size: 0.16rem !important;
    font-weight: bold !important;
    left: initial !important;
    right: initial !important;
    width: inherit;

    & .iconfont {
      width: 0.3rem;
      height: 0.36rem;
      line-height: 0.36rem;
      text-align: center;
      display: inline-block;
      color: #999;
      font-size: 0.2rem;
    }
  }

  #app.has-nav {
    padding-top: calc(env(safe-area-inset-top) + 0.47rem);

    & [class^=page-index],
    & .page-search,
    & .page-default {
      & .header {
        top: calc(env(safe-area-inset-top) + 0.47rem) !important;
      }
    }

    & .page-content .top,
    & .page-menu .menu-header,
    & .order-list .menu,
    & .service-list .menu,
    & .pop-in-enter-active,
    & .pop-in-leave-active,
    & .pop-out-enter-active,
    & .pop-out-leave-active,
    & .slide-down-enter-active,
    & .slide-down-leave-active,
    & .slide-up-enter-active,
    & .slide-up-leave-active,
    & .page-limit .menu {
      top: calc(env(safe-area-inset-top) + 0.47rem) !important;

      & .header {
        top: 0 !important;
      }
    }

    & .page-search .filter.fixed,
    & .page-menu .left {
      top: calc(env(safe-area-inset-top) + 0.91rem) !important;
    }

    & .page-detail .header {
      display: none;
    }

    & .page-limit .list {
      padding-top: calc(env(safe-area-inset-top) + 0.47rem);
    }

    & .page-search-input .like {
      top: calc(env(safe-area-inset-top) + 0.88rem)
    }
  }

  #app.has-nav.profilly-android-safe-area {
    & .native-header {
      padding-top: 0.4rem;
      height: 0.87rem !important;
    }
    padding-top: 0.87rem;

    & [class^=page-index],
    & .page-search,
    & .page-default {
      & .header {
        top: 0.87rem !important;
      }
    }

    & .page-content .top,
    & .page-menu .menu-header,
    & .order-list .menu,
    & .service-list .menu,
    & .pop-in-enter-active,
    & .pop-in-leave-active,
    & .pop-out-enter-active,
    & .pop-out-leave-active,
    & .slide-down-enter-active,
    & .slide-down-leave-active,
    & .slide-up-enter-active,
    & .slide-up-leave-active,
    & .page-limit .menu {
      top: 0.87rem !important;

      & .header {
        top: 0 !important;
      }
    }

    & .page-search .filter.fixed,
    & .page-menu .left {
      top: 1.31rem !important;
    }

    & .page-detail .header {
      display: none;
    }

    & .page-limit .list {
      padding-top: 0.87rem;
    }

    & .page-search-input .like {
      top: 1.28rem
    }
  }
</style>
<template>
  <mt-header fixed :title="$root.title" class="native-header border-bm">
    <div slot="left">
      <span class="iconfont icon-return" @click="back()"></span>
      <span class="iconfont icon-close" @click="close()" v-if="showClose"></span>
    </div>
    <span class="iconfont icon-homepage" slot="right" @click="home()" v-if="showLeft && showHome"></span>
  </mt-header>
</template>
<script>
  import { Header } from 'mint-ui'

  export default {
    name: 'myNav',
    components: {
      [Header.name]: Header
    },
    data () {
      return {
        top: 0,
        show: true,
        showLeft: true,
        showClose: true,
        showHome: true
      }
    },
    methods: {
      backForClose () {
        const curLink = window.location.href
        if (this.timer) {
          clearTimeout(this.timer)
        }
        history.back()
        this.timer = setTimeout(() => {
          if (window.location.href === curLink) {
            this.timer = null
            window.navBarClose && window.navBarClose()
          }
        }, 300)
      },
      back () {
        if (this.$route.path === '/index.html' && !this.$route.meta.noClose) {
          if (window.hybrid_app) {
            window.hybrid_app.back()
          } else {
            this.backForClose()
          }
        } else {
          this.backForClose()
        }
      },
      close () {
        if (window.hybrid_app) {
          window.hybrid_app.back(true)
        } else {
          window.close()
        }
      },
      home () {
        this.$router.push({
          path: '/index.html',
          meta: {
            noClose: true
          }
        })
      }
    },
    mounted () {
      if (window.navBarConfig) {
        this.showClose = window.navBarConfig.showClose === undefined ? true : window.navBarConfig.showClose
        this.showHome = window.navBarConfig.showHome === undefined ? true : window.navBarConfig.showHome
      }
    }
  }
</script>
