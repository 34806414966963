<template>
  <a :href="link.data" v-if="link.type === 'url'">
    <slot></slot>
  </a>
  <div v-else-if="link.type === ''">
    <slot></slot>
  </div>
  <router-link :to="dealLink(link)" v-else>
    <slot></slot>
  </router-link>
</template>
<script>
  export default {
    name: 'myLink',
    props: {
      link: {
        required: true,
        type: Object
      }
    },
    methods: {
      dealLink (link) {
        switch (link.type) {
          case 'keyword':
            return {
              path: '/search/list',
              query: {
                words: link.data
              }
            }
          case 'goods':
            return {
              path: '/detail',
              query: {
                id: link.data
              }
            }
          case 'special':
            return {
              path: '/special',
              query: {
                id: link.data
              }
            }
          case 'brand':
            return {
              path: '/brand/detail',
              query: {
                id: link.data
              }
            }
        }
        if (link.goods_id) {
          return {
            path: '/detail',
            query: {
              id: link.goods_id
            }
          }
        }
      }
    }
  }
</script>
