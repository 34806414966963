<style lang="postcss" scoped>
  .icon-return {
    position: absolute;
    left: 0;
    top: 0;
    width: 0.38rem;
    height: 0.44rem;
    line-height: 0.44rem;
    text-align: center;
  }
  .body {
    padding: 0.1rem 0.2rem;
  }
  .item {
    margin-bottom: 0.2rem;
    & .label {
      margin-right: 0.08rem;
      width: 0.5rem;
    }
    & .border:before {
      border-color: var(--base-color);
    }
    & input {
      margin: 0.1rem;
    }
    & .btn-submit {
      width: 0.8rem;
    }
  }
  .error {
    margin-left: 0.58rem;
    color: var(--base-color);
  }
</style>
<template>
  <pay-layout>
    <div slot="top">
      <div>{{title}}</div>
      <span class="iconfont icon-return" @click="backStep"></span>
    </div>
    <div class="body" slot="center">
      <div class="flex-left-center item">
        <span class="label">{{ needBindCardInfo.card_name ||'卡片卡号'}}</span>
        <div class="flex-1 border btn-radius">
          <input type="text" v-model.trim="card"/>
        </div>
      </div>
      <div class="flex-left-center item" v-if="needBindCardInfo.is_need_pwd === '1'">
        <span class="label">绑卡密码</span>
        <div class="flex-1 border btn-radius">
          <input type="password" v-model.trim="password"/>
        </div>
      </div>
      <div class="flex-left-center item">
        <span class="label">验&nbsp;&nbsp;证&nbsp;&nbsp;码</span>
        <div class="flex-1 border btn-radius">
          <input type="text" v-model.trim="captcha"/>
        </div>
        <captcha-img v-model="codekey" ref="captcha"/>
      </div>
      <div class="flex-left-center item">
        <span class="label"></span>
        <mt-button type="danger" size="small" class="btn-submit" @click="submit">绑定</mt-button>
      </div>
      <p class="error">{{errorMessage}}</p>
    </div>
  </pay-layout>
</template>
<script>
  import PayLayout from './Layout'
  import CaptchaImg from '../CaptchaImg'
  import { Button, Toast } from 'mint-ui'

  export default {
    name: 'MyBindCard',
    components: {
      PayLayout,
      CaptchaImg,
      [Button.name]: Button
    },
    data () {
      return {
        title: '绑定卡片',
        card: '',
        password: '',
        captcha: '',
        codekey: '',
        errorMessage: '',
        needBindCardInfo: window.needBindCardInfo || {}
      }
    },
    methods: {
      backStep () {
        this.$emit('step', { type: 'back' })
      },
      submit () {
        this.errorMessage = ''
        if (!this.card) {
          this.errorMessage = '卡片卡号不能为空！'
          return
        }
        if (!this.password && this.needBindCardInfo.is_need_pwd === '1') {
          this.errorMessage = '绑卡密码不能为空！'
          return
        }
        if (!this.captcha) {
          this.errorMessage = '验证码不能为空！'
          return
        }
        this.$http.post('', this.postData({
          card: this.card,
          password: this.password,
          captcha: this.captcha,
          codekey: this.codekey
        }), {
          params: {
            act: 'member_account',
            op: 'bindcard'
          }
        }).then(res => {
          if (res['datas'] === 1) {
            Toast({
              position: 'bottom',
              message: '绑定成功！'
            })
            window.eventBus.$emit('bindCardOk', true)
          }
          this.backStep()
        }).catch(() => {
          this.$refs.captcha.loadSeccode()
        })
      }
    }
  }
</script>
