<style
  lang="postcss"
  scoped
>
  .coin {
    line-height: 1;
    display: table;
    font-family: NUM_Regular, -apple-system, sans-serif;

    &.lighter {
      font-family: NUM_Light, -apple-system, sans-serif;
    }

    &.bolder {
      font-family: NUM_Bold, -apple-system, sans-serif;
    }

    & .currency {
      font-size: 0.12rem;
      padding: 0 0.01rem 0.01rem 0;
    }

    & .dot {
      font-weight: bolder;
      font-size: 0.12rem;
    }

    & .integer {
      font-size: inherit;
    }

    & .decimal {
      font-size: 0.12rem;
    }

    & .plus {
      padding: 0 0.05rem;
    }

    & .logic {

      & .currency {
        margin-left: 0.02rem;
        font-weight: bold;
      }
    }
  }
</style>
<template>
  <div
    class="coin"
    ref="coin"
    :class="{lighter:bold===0, bolder:bold===2}"
  >
    <template v-if="priceRule.type === 'cash' || priceRule.type === 'fullCash'">
      <template v-if="logicPoint">
        <span class="logic">{{ logicPoint }}<span class="currency">{{ priceRule.unit }}</span></span>
        <span class="plus">+</span>
      </template>
      <span class="currency">{{ currency.symbol }}</span>
      <span class="integer">{{ integer }}</span>
      <span
        class="dot"
        v-if="showDecimal && decimal"
      >.</span>
      <span
        class="decimal"
        v-if="showDecimal && decimal"
      >{{ decimal }}</span>
    </template>
    <template v-else-if="priceRule.type === 'point'">
      <span class="integer">{{ pointAll }}</span>
      <span class="currency">{{ priceRule.unit }}</span>
    </template>
    <template v-else-if="priceRule.type === 'pointAndCash'">
      <template v-if="priceRule.rule === 'percent'">
        <span class="integer">{{ percentPoint[0] }}</span>
        <span
          class="dot"
          v-if="percentPoint[1]"
        >.</span>
        <span
          class="decimal"
          v-if="percentPoint[1]"
        >{{ percentPoint[1] }}</span>
        <span class="currency">{{ priceRule.unit }}</span>
        <span class="add"> + </span>
        <span class="currency">{{ currency.symbol }}</span>
        <span class="integer">{{ percentCash[0] }}</span>
        <span
          class="dot"
          v-if="percentCash[1]"
        >.</span>
        <span
          class="decimal"
          v-if="percentCash[1]"
        >{{ percentCash[1] }}</span>
      </template>
      <template v-else-if="priceRule.rule === 'pointFixed'">
        <span class="integer">{{ pointFixed[0] }}</span>
        <span
          class="dot"
          v-if="pointFixed[1]"
        >.</span>
        <span
          class="decimal"
          v-if="pointFixed[1]"
        >{{ pointFixed[1] }}</span>
        <span class="currency">{{ priceRule.unit }}</span>
        <span class="add"> + </span>
        <span class="currency">{{ currency.symbol }}</span>
        <span class="integer">{{ cashSurplus[0] }}</span>
        <span
          class="dot"
          v-if="cashSurplus[1]"
        >.</span>
        <span
          class="decimal"
          v-if="cashSurplus[1]"
        >{{ cashSurplus[1] }}</span>
      </template>
      <template v-else-if="priceRule.rule === 'cashFixed'">
        <span class="currency">{{ currency.symbol }}</span>
        <span class="integer">{{ cashFixed[0] }}</span>
        <span
          class="dot"
          v-if="cashFixed[1]"
        >.</span>
        <span
          class="decimal"
          v-if="cashFixed[1]"
        >{{ cashFixed[1] }}</span>
        <span class="add"> + </span>
        <span class="integer">{{ pointSurplus[0] }}</span>
        <span
          class="dot"
          v-if="pointSurplus[1]"
        >.</span>
        <span
          class="decimal"
          v-if="pointSurplus[1]"
        >{{ pointSurplus[1] }}</span>
        <span class="currency">{{ priceRule.unit }}</span>
      </template>
    </template>
  </div>
</template>
<script>

  export default {
    name: "BaseCoin",
    props: {
      coin: {
        required: true
      },
      logicPoint: {
        type: String
      },
      bold: {
        type: Number,
        default: 1
      },
      priceRule: {
        type: Object,
        default: () => ({ type: "cash" })
      },
      currency: {
        type: Object,
        default: () => ({
          symbol: "¥",
          flag: 0,
          rate: 1
        })
      },
      showDecimal: {
        type: Boolean,
        default: true
      }
    },
    computed: {
      integer () {
        let arr = this.c()
        return arr[0]
      },
      decimal () {
        let arr = this.c()
        if (arr[1].indexOf("0") === 0) {
          if (arr[1] === '00') {
            return 0
          }
          return arr[1]
        }
        const c = Number(arr[1])
        if (c % 10 === 0) {
          return c / 10
        }
        return c
      },
      percentPoint () {
        let p = (this.coin * this.priceRule.ratio * this.priceRule.percentPoint / 100).toFixed(2)
        return p.split(".").map(val => this.d(val))
      },
      percentCash () {
        let p = (this.coin * (100 - this.priceRule.percentPoint) / 100).toFixed(2)
        return p.split(".").map(val => this.d(val))
      },
      pointFixed () {
        return this.priceRule.pointFixed.split(".").map(val => this.d(val))
      },
      cashSurplus () {
        let pointEqualCash = (this.priceRule.pointFixed / this.priceRule.ratio).toString()
        let indexDot = pointEqualCash.indexOf(".")
        if (indexDot !== -1) {
          pointEqualCash = pointEqualCash.slice(0, indexDot + 3)
        }
        let p = this.coin - pointEqualCash
        return p.toString().split(".").map(val => this.d(val))
      },
      cashFixed () {
        return this.priceRule.cashFixed.split(".").map(val => this.d(val))
      },
      pointSurplus () {
        let point = (this.coin - this.priceRule.cashFixed) * this.priceRule.ratio
        return point.toString().split(".").map(val => this.d(val))
      },
      pointAll () {
        const decimal = (this.c()[1] * this.priceRule.ratio / 100).toFixed(2) * 1
        return this.integer * this.priceRule.ratio + (this.priceRule.ratio > 100 ? Math.round(decimal) : decimal)
      }
    },
    methods: {
      c () {
        let price = this.coin
        if (this.currency.flag > 0) {
          price = price / this.currency.rate
        }
        let c = (price && (price * 1).toFixed(2)) || "0.00"
        return c.split(".")
      },
      d (val) {
        return val.replace("00", "").replace(/^([1-9])0*$/, function ($1, $2) {
          return $2
        })
      }
    }
  }
</script>
