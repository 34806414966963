<style
  lang="postcss"
  scoped
>
  .tool-bar {
    box-sizing: content-box;
    &.show {
      height: 0.44rem;
      padding-bottom: env(safe-area-inset-bottom);
    }

    & .actions {
      transition: 0.25s cubic-bezier(0.27, 1.21, 1, 1);
      transform: translateY(100%);
      height: inherit;
      position: fixed;
      width: inherit;
      bottom: -1px;
      z-index: 999999;
      padding-bottom: calc(env(safe-area-inset-bottom) / 1.5);
      text-align: center;
      backdrop-filter: blur(20px);
      background-color: rgba(255, 255, 255, 0.72);

      &.show {
        transform: translateY(0);
      }

      & > div {
        height: inherit;
        position: relative;
        z-index: 10;

        & .iconfont {
          font-size: 0.22rem;
          color: #555;
        }
      }
    }
  }

  .go-top {
    position: fixed;
    right: 0.1rem;
    bottom: 0.64rem;
    z-index: 999;
    width: 0.4rem;
    height: 0.4rem;
    line-height: 0.44rem;
    text-align: center;
    margin-bottom: env(safe-area-inset-bottom);
    transition: 0.25s cubic-bezier(0.27, 1.21, 1, 1);
    transform: translateY(1.5rem);

    &.show {
      transform: translateY(0);
    }

    & .iconfont {
      font-size: 0.34rem;
      opacity: 0.5;
      background: #fff;
      border-radius: 100%;
    }
  }
</style>
<template>
  <div class="tool-bar" :class="{show: showBackHome}" v-if="!$root.preview.inIframe">
    <div
      class="actions"
      :class="{show}"
      v-if="showBackHome && isNeed"
    >
      <div class="flex-center-center border-tp">
        <span
          class="flex-1 iconfont icon-return"
          @click="back()"
        ></span>
        <span
          class="flex-1 iconfont icon-enter"
          @click="forward()"
        ></span>
        <span
          class="flex-1 iconfont icon-homepage"
          @click="home()"
        ></span>
        <span
          class="flex-1 iconfont icon-cart"
          @click="cart()"
        ></span>
        <span
          class="flex-1 iconfont icon-order"
          @click="order()"
        ></span>
        <span
          class="flex-1 iconfont icon-people"
          @click="member()"
        ></span>
      </div>
    </div>
    <div
      class="go-top"
      @click="top"
      :class="{show:toTopShow}"
    >
      <span class="iconfont icon-top"></span>
    </div>
  </div>
</template>
<script>
  import { siteConfig } from '@/config'
  import { SETTOOLBARSTATE } from '@/store/module/toolBar'

  const { showBackHome = false } = siteConfig
  export default {
    name: 'ToolBar',
    data () {
      return {
        show: false,
        scrollTop: 0,
        toTopShow: false,
        showBackHome
      }
    },
    computed: {
      isNeed () {
        const arr = ['index.html', 'menu', 'cart', 'order', 'detail']
        return arr.indexOf(this.$route.name) === -1
      }
    },
    methods: {
      back () {
        if (this.$route.path === '/index.html' && !this.$route.meta.noClose) {
          if (window.navBackAction) {
            window.navBackAction()
            return
          }
          history.back()
          return
        }
        history.back()
      },
      forward () {
        history.forward()
      },
      home () {
        this.$router.push({
          path: '/index.html',
          meta: {
            noClose: true
          }
        }).then()
      },
      cart () {
        this.$router.push({
          path: '/cart'
        }).then()
      },
      order () {
        this.$router.push({
          path: '/order'
        }).then()
      },
      member () {
        this.$router.push({
          path: '/member'
        }).then()
      },
      top () {
        window.scrollTo(0, 0)
      },
      initiation () {
        window.onscroll = e => {
          const scrollTop = document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset
          // 工具条
          if (scrollTop <= 0) {
            this.show = true && showBackHome
            this.toTopShow = false
          } else {
            this.show = this.scrollTop > scrollTop && showBackHome
            if (!this.show) {
              this.toTopShow = false
            } else {
              this.toTopShow = scrollTop > window.screen.availHeight * 2
            }
          }
          this.scrollTop = scrollTop
          clearInterval(this.timer)
          this.interval()
        }
        this.interval()
      },
      interval () {
        this.timer = setTimeout(() => {
          this.show = true && showBackHome
          this.toTopShow = this.scrollTop > window.screen.availHeight * 2
        }, 1000)
      }
    },
    watch: {
      show (curr) {
        this.$store.commit(SETTOOLBARSTATE, {
          show: curr && showBackHome,
          isNeed: this.isNeed
        })
      },
      '$route.name' () {
        this.$store.commit(SETTOOLBARSTATE, {
          show: false,
          isNeed: this.isNeed
        })
      }
    },
    created () {
      this.initiation()
    }
  }
</script>
