<style lang="postcss">
  .prompt-box {
    background: #fff;
    width: 2.6rem;
    border-radius: 0.12rem;
    overflow: hidden;

    & .top {
      padding: 0.16rem 0 0.04rem;
      font-size: 0.14rem;
      font-weight: bold;
      color: #000;
      text-align: center;
    }

    & .body {
      padding:  0.2rem;
      text-align: center;
      color: #888;

      & .item {
        padding: 0.08rem;
        &:before {
          border-radius: 0.2rem;
          border-color: #d9d9d9;
        }
        & input {
          width: 100%;
          text-align: left;
          font-size: 0.12rem;
        }
        margin-bottom: 0.1rem;

      }

      &:after {
        background: #eee;
      }
    }

    & .bottom {
      text-align: center;
      padding: 0;

      & button {
        box-sizing: border-box;
        padding: 0.12rem 0;
        font-size: 0.14rem;
        background: #fff;
        border: none;
        margin: 0;

        &:active {
          background: #f8f8f8;
        }

        &.cancel {
          color: #333;
        }

        &.confirm {
          color: var(--base-color);

          &:after {
            background: #eee;
          }
        }
      }
    }
  }
</style>
<template>
  <my-model-box
    :show="show"
    @close="cancel"
    v-if="visible"
  >
    <div
      class="prompt-box"
      slot="body"
    >
      <div
        class="top"
        v-html="title||''"
      ></div>
      <div class="body border-bm">
        <div
          class="border item"
          v-for="val in userInput"
          :key="val.name"
        >
          <input
            v-model="params[val.name]"
            :placeholder="val.label"
            :name="val.name"
          />
        </div>
      </div>
      <div class="bottom flex">
        <button
          @click="cancel"
          class="flex-1 cancel border-rt"
        >{{ btn && btn[0] || "取消" }}
        </button>
        <button
          @click="confirm"
          class="flex-1 confirm"
        >{{ btn && btn[1] || "确定" }}
        </button>
      </div>
    </div>
  </my-model-box>
</template>
<script>
  import modelBox from './Model.vue'

  export default {
    components: {
      [modelBox.name]: modelBox
    },
    props: ['title', 'btn', 'resolve', 'reject', 'visible', 'userInput'],
    data () {
      return {
        show: true,
        params: {}
      }
    },
    methods: {
      close () {
        this.show = false
      },
      cancel () {
        this.reject('cancel')
        this.close()
      },
      confirm () {
        this.resolve(this.params)
        this.close()
      }
    }
  }
</script>
