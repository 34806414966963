export const LOADING = 'LOADING'

export default {
  state: {
    loading: false
  },
  mutations: {
    [LOADING] (state, payload) {
      state.loading = payload
    }
  }
}
