import Vue from 'vue'
import MessageBox from './MessageBox.vue' // 引入组件
let MessageBoxConstructor = Vue.extend(MessageBox) // 返回一个“扩展实例构造器”

let MessageBoxFun = (options = {}) => {
  let el = document.createElement('div')
  document.body.appendChild(el)

  new MessageBoxConstructor({
    propsData: {
      ...options
    }
  }).$mount(el)
}

export default MessageBoxFun
