const toLowerUpper = str => {
  const re = /-(\w)/g
  return str.replace(re, ($0, $1) => $1.toUpperCase())
}

const formatBorderRadius = obj => {
  if (obj.center) {
    return { borderRadius: obj.center }
  }
  const temp = {}
  if (obj.top) {
    temp.borderTopLeftRadius = obj.top
  }
  if (obj.right) {
    temp.borderTopRightRadius = obj.right
  }
  if (obj.bottom) {
    temp.borderBottomRightRadius = obj.bottom
  }
  if (obj.left) {
    temp.borderBottomLeftRadius = obj.left
  }
  return temp
}

const formatShadow = (obj = {}) => {
  const { box = {}, text = {} } = obj
  const temp = {}
  if (box.x && box.y) {
    const { x, y, blur, spread, color, inset } = box
    temp.boxShadow = [x, y, blur, spread, color, inset ? 'inset' : ''].join(' ')
  }
  if (text.x && text.y) {
    const { x, y, blur, color, inset } = text
    temp.textShadow = [x, y, blur, color, inset ? 'inset' : ''].join(' ')
  }
  return temp
}

const formatFont = (obj = {}) => {
  const temp = {}
  Object.keys(obj).map(val => {
    if (!obj[val]) {
      return
    }
    if (/[A-Z]/.test(val) || val === 'color') {
      temp[val] = obj[val]
    } else {
      const k = toLowerUpper(`font-${val}`)
      temp[k] = obj[val]
    }
  })
  return temp
}

const format = (obj, keyType) => {
  const temp = {}
  if (keyType === 'font') {
    Object.assign(temp, formatFont(obj))
  }
  if (keyType === 'shadow') {
    Object.assign(temp, formatShadow(obj))
  }
  if (keyType === 'margin' || keyType === 'padding') {
    if (obj.center) {
      temp[keyType] = obj.center
    } else {
      Object.keys(obj).forEach(i => {
        if (obj[i]) {
          temp[toLowerUpper(`${keyType}-${i}`)] = obj[i]
        }
      })
    }
    return temp
  }
  // border-* background-* 类型
  Object.keys(obj).forEach(key => {
    const val = obj[key]
    if (!val) {
      return
    }
    if (key === 'radius') {
      Object.assign(temp, formatBorderRadius(val))
      return
    }
    const k = toLowerUpper(`${keyType}-${key}`)
    if (typeof val === 'object') {
      if (val.center) {
        temp[k] = val.center
      } else {
        const o = {}
        Object.keys(val).forEach(i => {
          if (val[i]) {
            o[toLowerUpper(`${keyType}-${i}-${key}`)] = val[i]
          }
        })
        Object.assign(temp, o)
      }
      return
    }
    temp[k] = k === 'backgroundImage' ? `url(${val})` : val
  })
  return temp
}

export default obj => {
  const temp = {}
  if (!obj) {
    return temp
  }
  const { background = {}, border = {}, fill = {}, shadow = {}, font = {}, box = {} } = obj

  const { margin, padding } = fill

  if (background) {
    Object.assign(temp, format(background, 'background'))
  }
  if (border) {
    Object.assign(temp, format(border, 'border'))
  }
  if (margin) {
    Object.assign(temp, format(margin, 'margin'))
  }
  if (padding) {
    Object.assign(temp, format(padding, 'padding'))
  }
  Object.assign(temp, format(shadow, 'shadow'))

  Object.assign(temp, format(font, 'font'))

  Object.assign(temp, box)
  return temp
}
