<style
  lang="postcss"
  scoped
>
  .money-box {
    padding: 0.08rem;

    & .need-pay {
      font-weight: bold;
      font-size: 0.24rem;
      margin: 0 auto;
      padding: 0.2rem;

      &.small {
        padding-bottom: 0.05rem;
      }
    }

    & .pay-tips {
      margin-bottom: 0.02rem;

      & .label {
        color: #999;
      }

      & .dark {
        color: #444;
      }
    }

    & .payed-tips {
      margin-bottom: 0.06rem;
      color: var(--base-color);
    }

    & .border-bm {
      margin-left: -0.08rem;
      margin-right: -0.08rem;
    }
  }
</style>

<template>
  <div class="money-box">
    <div v-if="is_use_yue">
      <my-coin
        class="flex-center-center need-pay small"
        :coin="pay_money"
      />
      <my-coin
        :coin="pay_amount"
        class="flex-center-center"
        style="text-decoration: line-through"
      />
    </div>
    <my-coin
      :coin="pay_amount"
      class="flex-center-center need-pay"
      v-else
    />
    <template v-if="payed_amount">
      <div class="flex-between pay-tips">
        <span class="label">订单金额</span>
        <my-coin
          :coin="total"
          class="dark"
        />
      </div>
      <div class="flex-between payed-tips">
        <span class="label">
          {{ payed_name.name || "已付" }}金额
          <span v-if="payed_name.radio">
            （{{ payed_name.radio + payed_name.unit }} = 1元）
          </span>
        </span>
        <my-coin
          :coin="payed_amount"
          class="dark"
        />
      </div>
      <div class="border-bm"></div>
    </template>
  </div>
</template>
<script>
  import MyCoin from "../Coin/Index.vue"

  export default {
    name: "Money",
    components: {
      MyCoin
    },
    props: {
      pay_amount: {
        type: Number,
        required: true,
        default: 0
      },
      payed_amount: {
        type: Number,
        required: true,
        default: 0
      },
      payed_name: {
        type: Object,
        default: function () {
          return {
            name: ""
          }
        }
      },
      member_available_pd: {
        type: Number,
        required: true,
        default: 0
      },
      is_use_yue: {
        type: Boolean,
        required: true,
        default: false
      }
    },
    computed: {
      total () {
        return this.payed_amount + this.pay_amount
      },
      pay_money () {
        const temp = this.total - this.member_available_pd
        return temp >= 0 ? temp : 0
      }
    }
  }
</script>
