<style lang="postcss" scoped>
  .alert-box {
    background: #fff;
    width: 2.6rem;
    border-radius: 0.12rem;
    overflow: hidden;

    & .top {
      padding: 0.16rem 0 0.04rem;
      font-size: 0.14rem;
      font-weight: bold;
      color: #000;
      text-align: center;
    }

    & .body {
      padding: 0.08rem 0.08rem 0.2rem;
      text-align: center;
      color: #888;

      &:after {
        background: #eee;
      }
    }

    & .bottom {
      padding: 0.12rem 0;
      font-size: 0.14rem;
      background: #fff;
      text-align: center;

      &:active {
        background: #f8f8f8;
      }
    }
  }
</style>
<template>
  <my-model-box :show="show" v-on:close="close" v-if="visible">
    <div class="alert-box" slot="body">
      <div class="top">{{title || ''}}</div>
      <div class="body border-bm" v-html="message"></div>
      <div class="bottom" @click="close">{{btn || '确定'}}</div>
    </div>
  </my-model-box>
</template>
<script>
  import Model from './Model.vue'

  export default {
    components: {
      [Model.name]: Model
    },
    props: ['title', 'message', 'btn', 'callback', 'visible'],
    data () {
      return {
        show: true
      }
    },
    methods: {
      close () {
        this.callback && this.callback()
        this.show = false
      }
    }
  }
</script>
