<style lang="postcss" scoped>
  .pay-box {
    height: 100%;
    background: #fff;
  }

  .pay-top {
    padding: 0.12rem;
    font-size: 0.16rem;
    text-align: center;
    width: 100%;
    box-sizing: border-box;
  }

  .pay-money {
    width: 100%;
    padding: 0.1rem 0;
  }

  .pay-center {
    width: 100%;
    overflow: scroll;
    overflow-scrolling: touch;
    box-sizing: border-box;
    position: relative;
  }

  .pay-bottom {
    width: 100%;
    background: #fff;
    margin-bottom: env(safe-area-inset-bottom);
  }
</style>
<template>
  <div class="flex-between flex-column pay-box">
    <div class="pay-top border-bm">
      <slot name="top"/>
    </div>
    <div class="pay-money">
      <slot name="money"/>
    </div>
    <div class="flex-1 pay-center">
      <slot name="center"/>
    </div>
    <div class="pay-bottom">
      <slot name="bottom"/>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'PayLayout'
  }
</script>
