import { Toast } from 'mint-ui'
import { siteConfig } from '../config'

const { enableVoucher = false } = siteConfig

export default {
  data () {
    return {
      list: {},
      member: {},
      allowReceive: [],
      alreadyReceive: [],
      enableVoucher
    }
  },
  methods: {
    initiation () {
      if (!this.enableVoucher) {
        return
      }
      this.$http.get('', {
        params: {
          act: 'voucher_return',
          op: 'returnVoucherList',
          goods_id: this.goodsId,
          activity_id: this.venueId
        },
        showMessage: this.showMessage || false
      }).then(res => {
        let temp = res.datas.list
        for (let key in temp) {
          Object.keys(temp[key]).forEach(val => {
            temp[key][val.replace('voucher_', '')] = temp[key][val]
            delete temp[key][val]
          })
        }
        this.list = res.datas.list
        this.member = res.datas.member
        this.alreadyReceive = []
        this.allowReceive = []
        // 可领取优惠券列表
        for (let key in this.list) {
          let v = this.list[key]
          // var total = Number(v.t_total)
          // var limit = Number(v.t_eachlimit)
          // var getCount = Number(this.member[key]['cishu'])
          // if ((limit === 0 && total > getCount) || limit > getCount) {
          this.allowReceive.push(v)
          // }
        }
        // 已领
        for (let key in this.member) {
          let temp = this.member[key]
          if (temp.cishu * 1 > 0) {
            this.alreadyReceive.push({
              ...this.list[key],
              num: temp.cishu
            })
          }
        }
      }).catch(() => {})
    },
    saveVoucher (item) {
      this.$http.post('', this.postData({
        tid: item.t_id,
        activity_id: item.activity_id
      }), {
        params: {
          act: 'member_voucher',
          op: 'voucher_freeex'
        }
      }).then(res => {
        if (res.datas === '1') {
          this.initiation()
          Toast({
            position: 'bottom',
            message: '领取成功！'
          })
        }
      })
    }
  },
  created () {
    this.initiation()
  }
}
