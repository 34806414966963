import Vue from 'vue'

import { siteConfig } from '@/config'
import { Alert } from '@/components'

const { delExplain: priceExplain = '', hideDel = false } = siteConfig

Vue.directive('price', {
  inserted: el => {
    if (!priceExplain || hideDel) {
      return
    }

    const fragment = document.createDocumentFragment()
    const span = document.createElement('span')
    span.className = 'iconfont icon-tips'
    span.style.cssText = 'line-height:0.7;color:inherit;font-size: 0.16rem;margin-left: 0.05rem;margin-top: 0.03rem'
    span.addEventListener('click', () => {
      Alert({
        title: '价格说明',
        message: priceExplain
      })
    }, false)
    fragment.append(span)
    el.appendChild(fragment)
  }
})
