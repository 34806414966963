<style
  lang="postcss"
  scoped
>
  .geolocation {
    padding: 0 0.16rem;

    & .icon {
      width: 0.16rem;
      height: 0.16rem;
      margin-top: -0.01rem;
      margin-right: 0.04rem;
      color: #ccc;
    }
  }
</style>
<template>
  <div class="flex-left-center geolocation" v-if="geographical">
    <div class="flex-left-center flex-1">
      <img
        src="@/assets/geolocation.svg"
        alt=""
        class="icon"
      />
      <span>定位：{{ addrText }}</span>
    </div>
    <mt-button
      size="small"
      v-if="flag"
      @click="useAddr"
    >使用定位
    </mt-button>
    <mt-button size="small" v-if="!flag" @click="position(false)">重试定位</mt-button>
  </div>
</template>
<script>
  import { position, setAddr } from "@/utils/geolocation"
  import { Button } from "mint-ui"
  import {siteConfig} from "@/config"
  const {geographical = false} = siteConfig

  export default {
    name: "MyGeolocation",
    components: {
      [Button.name]: Button
    },
    data () {
      return {
        addrArr: [],
        addrText: '进行中...',
        flag: false,
        geographical
      }
    },
    methods: {
      position,
      initiation () {
        window.eventBus.$on("geolocationSuccess", res => {
          if (res.every(item => item.area_name)) {
            this.addrArr = res
            this.addrText = res.slice(0,2).map(v => v.area_name).join('')
            this.flag = true
          } else {
            this.addrText='失败'
          }
        })
        window.eventBus.$on("geolocationFalse", () => {
          this.addrText = '失败'
        })
        position(false)
      },
      useAddr () {
        setAddr(this.addrArr)
        this.$emit('change')
      }
    },
    created () {
      this.initiation()
    }
  }
</script>
