<style lang="postcss" scoped>
  .slide-del {
    position: relative;
    min-height: 0.2rem;
    background: var(--base-color);
    width: 100%;
    overflow: hidden;

    & .slide {
      background: #fff;
      width: 100%;
      pointer-events: auto;
    }

    & .del {
      height: 100%;
      width: 0.9rem;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 1;
      transform-origin: center;
      transform: translate(100%);
      color: #fff;
      text-align: center;
    }
  }
</style>
<template>
  <div class="slide-del">
    <div class="slide"
         :style="mainStyle"
         @touchstart='touchStart'
         @touchmove='touchMove'
         @touchend='touchEnd'
         @transitionend="transitionEnd"
         @click="close"
    >
      <slot name="slide"></slot>
    </div>
    <div class="flex-center-center del" :style="delStyle" ref="del" @click="deleteThis">
      <slot name="del"></slot>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'mySlideDel',
    data () {
      return {
        eventName: {
          start: 'touchstart',
          move: 'touchmove',
          end: 'touchend'
        },
        touch: {
          id: '',
          start: {},
          begin: {},
          diff: 0,
          isOpen: false
        },
        transition: {
          transition: 'transform 0.1s ease-out'
        },
        mainStyle: {},
        delStyle: {},
        delWidth: 0,
        timer: 0
      }
    },
    mounted () {
      // this.delWidth = this.$refs.del.scrollWidth;
      if (process.env.NODE_ENV === 'development') {
        this.timer = setTimeout(() => {
          this.timer = 0
          this.delWidth = this.$refs.del.clientWidth
        }, 1000)
        return
      }
      this.delWidth = this.$refs.del.clientWidth || this.$refs.del.scrollWidth
    },
    methods: {
      touchStart (e) {
        if (e.type === this.eventName.start) {
          this.touch.id = e.targetTouches[0].identifier
        }
        this.touch.start.x = e.type === this.eventName.start ? e.targetTouches[0].pageX : e.pageX
        this.touch.start.y = e.type === this.eventName.start ? e.targetTouches[0].pageY : e.pageY
        this.touch.begin.x = this.touch.start.x
        this.touch.begin.y = this.touch.start.y
        window.eventBus.$emit('closeSlideDel', this.$el)
      },
      touchMove (e) {
        let pageX, pageY, touch, translateX, mainTranslateX, delTranslateX
        if (e.type === this.eventName.move) {
          if (this.touch.id && e.touches) {
            for (let i = 0; i < e.touches.length; i++) {
              if (e.touches[i].identifier === this.touch.id) {
                touch = e.touches[i]
              }
            }
          }
          if (!touch) {
            touch = e.targetTouches[0]
          }
          pageX = touch.pageX
          pageY = touch.pageY
        } else {
          pageX = e.pageX
          pageY = e.pageY
        }
        if (!pageX || !pageY) {
          return
        }
        this.touch.diff = pageX - this.touch.start.x
        // x轴上的移动距离（正数值）
        translateX = this.easyOut(this.touch.diff / window.innerWidth) * window.innerWidth / 2
        // 没有显示del按钮 即正常打开
        if (!this.isOpen) {
          // 不允许向右滑动
          if (this.touch.diff > 0) {
            return
          }
          mainTranslateX = this.returnTransformVal(translateX * -1)
          delTranslateX = this.returnTransformVal((this.delWidth - translateX < 0) ? (translateX < this.delWidth / 4
            ? this.delWidth
            : 0) : (this.delWidth - translateX))
        } else {
          // 向左
          if (this.touch.diff < 0) {
            translateX = translateX * -1
          }
          // 向右滑动区域超出屏幕
          if (translateX - this.delWidth > 0) {
            mainTranslateX = this.returnTransformVal(0)
            delTranslateX = this.returnTransformVal(this.delWidth)
          } else {
            mainTranslateX = this.returnTransformVal(translateX - this.delWidth)
            delTranslateX = this.returnTransformVal(translateX < 0 ? 0 : translateX)
          }
        }

        // x轴滑动幅度大于y轴
        if (Math.abs(pageY - this.touch.begin.y) * 1.732 < Math.abs(pageX - this.touch.begin.x)) {
          // this.styles['transform'] = 'translateX('+(this.touch.diff/2)+'px)';
          this.mainStyle = {
            transform: mainTranslateX
          }
          this.delStyle = {
            transform: delTranslateX
          }
          e.stopPropagation()
          e.preventDefault()
        }
        this.touch.begin.x = pageX
        this.touch.begin.y = pageY
      },
      touchEnd (e) {
        let pageX = e.type === this.eventName.end ? e.changedTouches[0].pageX : e.pageX
        let move = 0
        let door = this.delWidth / 4
        this.touch.diff = pageX - this.touch.start.x
        if (this.touch.diff === 0) {
          return
        }
        move = Math.abs(this.touch.diff)
        // 如果处于打开状态
        if (this.isOpen) {
          // 向左
          if (this.touch.diff < 0) {
            this.open()
            return
          }
          // 只要移动距离大于del区域的1/4
          if (move > door) {
            this.close()
            return
          }
          this.open()
          return
        }
        // 处于关闭状态
        if (this.touch.diff > 0) {
          return
        }
        if (move > door) {
          this.open()
          return
        }
        this.close()
      },
      open () {
        this.mainStyle = {
          ...this.mainStyle,
          ...this.transition
        }
        this.delStyle = {
          ...this.delStyle,
          ...this.transition
        }
        setTimeout(() => {
          this.mainStyle = {
            ...this.mainStyle,
            transform: this.returnTransformVal(this.delWidth * -1)
          }
          this.delStyle = {
            ...this.delStyle,
            transform: this.returnTransformVal(0)
          }
        }, 10)
        this.isOpen = true
        // console.log('可以开启');
      },
      close () {
        // if (!this.isOpen) return
        this.mainStyle = {
          ...this.mainStyle,
          ...this.transition
        }

        this.delStyle = {
          ...this.delStyle,
          ...this.transition
        }
        setTimeout(() => {
          this.mainStyle = {
            ...this.mainStyle,
            transform: this.returnTransformVal(0)
          }
          this.delStyle = {
            ...this.delStyle,
            transform: this.returnTransformVal(this.delWidth)
          }
        }, 10)
        this.isOpen = false
        // console.log('不可以开启');
      },
      transitionEnd () {
        let ani = { transition: 'none' }
        this.mainStyle = {
          ...this.mainStyle,
          ...ani
        }
        this.delStyle = {
          ...this.delStyle,
          ...ani
        }
      },
      easyOut (x) {
        return (Math.abs(x) * 2 - Math.pow(x, 2))
      },
      returnTransformVal (num) {
        return 'translateX(' + num + 'px)'
      },
      deleteThis () {
        this.$emit('delete', null)
        this.close()
      }
    },
    created () {
      window.eventBus.$on('closeSlideDel', (el) => {
        if (this.isOpen && this.$el !== el) this.close()
      })
    },
    destroyed () {
      if (this.timer !== 0) {
        clearTimeout(this.timer)
      }
    }
  }
</script>
