<style
  lang="postcss"
  scoped
>
  .delete {
    font-size: 0.12rem;
    color: #aaa;
    line-height: 1;
  }

  .line {
    text-decoration: line-through;
    & > * {
      text-decoration: inherit;
    }
  }

  .normal {
    color: var(--base-color);
  }
</style>
<template>
  <div
    class="delete"
    v-if="isDelPrice"
  >
    <base-coin
      class="line"
      :coin="realCoin"
      :priceRule="delPriceRule"
      :currency="currency"
      :bold="fontBold"
      v-if="!hideDel"
      :logic-point="logicPoint"
      :showDecimal="showDecimal"
    />
  </div>
  <div
    class="normal"
    v-else
  >
    <base-coin
      :coin="realCoin"
      :priceRule="priceRule"
      :currency="currency"
      :bold="fontBold"
      :logic-point="logicPoint"
      :showDecimal="showDecimal"
    />
    <base-coin
      :coin="realCoin"
      :priceRule="priceRule2"
      :currency="currency"
      :bold="fontBold"
      v-if="showPrice2"
      :logic-point="logicPoint"
      :showDecimal="showDecimal"
    />
  </div>
</template>
<script>
  import BaseCoin from "./Coin.vue"

  import { siteConfig } from "@/config"

  const {
    priceRule = {
      type: "cash"
    },
    showPrice2 = false,
    priceRule2 = {
      type: "cash"
    },
    hideDel = false,
    delPriceRule = {
      type: "cash"
    },
    showDel = false
  } = siteConfig

  export default {
    name: "MyCoin",
    components: {
      BaseCoin
    },
    props: {
      coin: {
        required: true
      },
      convertCoin: {
        type: [undefined, String, null]
      },
      showDecimal: {
        type: Boolean,
        default: true
      }
    },
    data () {
      return {
        priceRule,
        priceRule2,
        showPrice2,
        hideDel: showDel !== true,
        delPriceRule,
        isDelPrice: false,
        fontBold: 1,
        rate: 1,
        currency: {
          symbol: "¥",
          flag: 0,
          rate: 1
        },
        realCoin: undefined,
        logicPoint: undefined
      }
    },
    watch: {
      coin (curr) {
        if (curr) {
          this.dealCurrency()
        }
      }
    },
    methods: {
      dealCurrency () {
        // 货币类型
        let currencyFlag = Number(this.$cookie.get("currency") || 0)
        let currencyRate = Number(this.$cookie.get("usRate") || 1)
        if (currencyFlag > 0) {
          if (!this.convertCoin) {
            if (this.convertCoin !== undefined) {
              currencyFlag = 0
              currencyRate = 1
            }
            this.realCoin = this.coin
          } else {
            currencyRate = 1
            this.realCoin = this.convertCoin
          }
        } else {
          if (!this.coin) {
            return
          }
          const [logicPoint, cash] = this.coin.toString().split("|")
          if (cash) {
            this.realCoin = cash
            this.logicPoint = logicPoint
          } else {
            this.realCoin = this.coin
          }
        }
        this.currency.flag = currencyFlag
        this.currency.rate = currencyRate
        this.currency.symbol = ["¥", "$"][currencyFlag]
      }
    },
    mounted: function () {
      this.$nextTick(() => {
        let completeStyle = window.getComputedStyle(this.$el, null)
        let fontWeight = completeStyle.getPropertyValue("font-weight")
        let textDecoration = completeStyle.getPropertyValue("text-decoration")
        // 判断字体粗细
        if (fontWeight >= 700 || ["bold", "bolder"].includes(fontWeight)) {
          this.fontBold = 2
        } else if (fontWeight >= 300 || fontWeight === "normal") {
          this.fontBold = 1
        } else {
          this.fontBold = 0
        }
        // 为划线价
        this.isDelPrice = textDecoration.indexOf("line-through") !== -1
        this.dealCurrency()
      })
    }
  }
</script>
