export const SET_FIRST = 'SET_FIRST'
export const SET_SECOND = 'SET_SECOND'
export const SET_CLEAR = 'SET_CLEAR'

export default {
  state: {
    first: {
      list: [],
      active: undefined,
      position: 0
    },
    second: {} // {gcId:[]}
  },
  mutations: {
    [SET_FIRST] (state, payload) {
      state.first = Object.assign({}, state.first, payload)
    },
    [SET_SECOND] (state, payload) {
      state.second = Object.assign({}, state.second, payload)
    },
    [SET_CLEAR] (state) {
      state.first = {
        list: [],
        active: undefined,
        position: 0
      }
      state.second = {}
    }
  }
}
