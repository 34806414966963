import { render, staticRenderFns } from "./ModelBottom.vue?vue&type=template&id=2580a9ab&scoped=true&"
import script from "./ModelBottom.vue?vue&type=script&lang=js&"
export * from "./ModelBottom.vue?vue&type=script&lang=js&"
import style0 from "./ModelBottom.vue?vue&type=style&index=0&id=2580a9ab&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2580a9ab",
  null
  
)

export default component.exports