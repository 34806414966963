<style lang="postcss" scoped>
  .model-bottom {
    background: #fff;
    min-height: 4rem;
    width: inherit;

    & .icon-close {
      position: absolute;
      right: 0;
      top: 0;
      padding: 0.08rem;
      width: 0.2rem;
      height: 0.2rem;
      line-height: 0.2rem;
      text-align: center;
      z-index: 10;
    }
  }
</style>
<template>
  <mt-popup
    v-model="popupVisible"
    position="bottom"
    class="model-bottom"
    :closeOnClickModal="closeOnClickModal"
    :style="customStyles"
  >
    <span class="iconfont icon-close" @click="close"></span>
    <slot></slot>
  </mt-popup>
</template>
<script>
  import { Popup } from 'mint-ui'
  import fixedWidth from "@/mixins/fixedWidth"

  export default {
    name: 'my-model-bottom',
    mixins: [fixedWidth],
    components: {
      [Popup.name]: Popup
    },
    model: {
      prop: 'show',
      event: 'close'
    },
    props: {
      show: {
        type: Boolean,
        required: true
      },
      closeOnClickModal: {
        type: Boolean,
        default: true
      }
    },
    data () {
      return {
        popupVisible: false
      }
    },
    methods: {
      close () {
        this.$emit('close', false)
      }
    },
    watch: {
      popupVisible (curr) {
        if (curr === false) {
          this.$emit('close', false)
          document.body.removeAttribute('style')
        } else {
          document.body.style.cssText = 'height: 100%;overflow:hidden;'
        }
      },
      show (curr) {
        this.popupVisible = curr
      }
    },
    destroyed () {
      document.body.removeAttribute('style')
    }
  }
</script>
