<style lang="postcss" scoped>
  .sms {
    margin-top: 0.08rem;
    color: #999;

    & .btn-code {
      margin-left: 0.04rem;
    }
  }
</style>
<template>
  <div class="flex-center-center sms">
    <p v-if="phone">短信已发送至：{{phone}}</p>
    <span class="btn-code" @click="getCaptcha">
      {{waitText}}
    </span>
  </div>
</template>
<script>
  import { Button } from 'mint-ui'

  export default {
    name: 'SendSms',
    components: {
      [Button.name]: Button
    },
    data () {
      return {
        phone: '',
        waitText: '点击重发',
        sendLoading: false
      }
    },

    methods: {
      initiation () {
        this.$http.get('', {
          params: {
            act: 'member_account',
            op: 'get_mobile_info'
          }
        }).then(res => {
          if (res.datas.state) {
            let phone = res.datas.mobile
            this.phone = phone.slice(0, 4) + '****' + phone.slice(-4)
            this.getCaptcha()
          }
        })
      },
      getCaptcha () {
        // 获取验证码
        this.sendLoading = true
        this.tempText = this.waitText
        this.$http.post('', '', {
          params: {
            act: 'member_account',
            op: 'modify_paypwd_step2'
          }
        }).then(res => {
          if (res.datas.sms_time) {
            // 倒计时
            this.countDown(res.datas.sms_time, arr => {
              this.waitText = arr[3] + 's后可重发'
            }, () => {
              this.sendLoading = false
              this.waitText = this.tempText
            })
          } else {
            throw new Error(res)
          }
        }).catch(() => {
          this.sendLoading = false
          this.waitText = this.tempText
        })
      }
    },
    created () {
      this.initiation()
    }
  }
</script>
