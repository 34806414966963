<style lang="postcss">
  .pay-assets {
    padding: 0.08rem;

    & .label {
      color: #999;

      & .iconfont {
        font-size: .16rem;
        margin-right: 0.03rem;
      }
    }

    & .icon-enter {
      color: #aaa;
      margin-right: -0.04rem;
      font-size: 0.18rem;
    }

    & .dark {
      color: #ccc;
      margin-left: 0.04rem;
    }

    & .flag {
      font-size: 0.11rem;
      color: #ccc;
      margin-bottom: -0.02rem;
      display: block;
      margin-left: 0.01rem;
    }

    & .light {
      color: var(--base-color);
      margin-top: -0.01rem;

      & > span {
        margin-right: 0.02rem;
      }
    }
  }
</style>
<template>
  <div class="flex-between border-bm pay-assets" @click="$emit('step')" v-if="assets">
    <div class="flex-center-center label">
      <slot name="label"/>
      <my-coin :coin="assets" class="dark"/>
      <span class="flag">可用</span>
      <slot name="desc"/>
    </div>
    <div class="flex-center-center">
      <div class="flex-center-center light" v-if="isUseAssets">
        <span>-</span>
        <my-coin :coin="used"/>
      </div>
      <div class="flex-center-center" v-else>
        <span class="use">点击使用</span>
      </div>
      <span class="iconfont icon-enter"></span>
    </div>
  </div>
</template>
<script>
  import MyCoin from '../Coin/Index.vue'

  export default {
    name: 'Assets',
    components: {
      MyCoin
    },
    props: {
      isUseAssets: {
        type: Boolean,
        required: true
      },
      assets: {
        type: Number,
        required: true,
        default: 0
      },
      payAmount: {
        type: Number,
        required: true,
        default: 0
      }
    },
    computed: {
      used () {
        let b = this.assets > this.payAmount
        return b ? this.payAmount : this.assets
      }
    }
  }
</script>
