<style lang="postcss" scoped>
  .icon-return {
    position: absolute;
    left: 0;
    top: 0;
    width: 0.38rem;
    height: 0.44rem;
    line-height: 0.44rem;
    text-align: center;
  }

  .yue-switch {
    padding: 0.06rem;
    width: 100%;
    box-sizing: border-box;
    background: #f8f8f8;
    margin: 0.4rem 0;
  }

  .switch {
    transform: scale(0.8);
    transform-origin: center;
  }
</style>
<template>
  <pay-layout>
    <div slot="top">
      <div>{{title}}</div>
      <span class="iconfont icon-return" @click="backStep"></span>
    </div>
    <div slot="center">
      <div class="flex-between border-bm yue-switch">
        <span>{{label}}</span>
        <mt-switch class="switch" v-model="status" @change="valChange"/>
      </div>
    </div>
  </pay-layout>
</template>
<script>
  import PayLayout from './Layout'
  import { Switch } from 'mint-ui'

  export default {
    name: 'MyScene2',
    components: {
      PayLayout,
      [Switch.name]: Switch
    },
    props: {
      value: {
        type: Boolean,
        required: true
      },
      title: {
        type: String,
        required: true,
        default: ''
      },
      label: {
        type: String,
        required: true,
        default: ''
      }
    },
    data () {
      return {
        status: false
      }
    },
    watch: {
      value (curr) {
        this.status = curr
      }
    },
    methods: {
      backStep () {
        this.$emit('step', { type: 'back' })
      },
      valChange () {
        this.$emit('input', this.status)
        setTimeout(() => {
          this.backStep()
        }, 300)
      }
    },
    created () {
      this.status = this.value
    }
  }
</script>
