<style
  lang="postcss"
  scoped
>
  .goods-info {
    & img {
      width: 0.7rem;
      margin-right: 0.08rem;
    }
    & .flag {
      background: var(--base-color);
      color: #fff;
      border-radius: 0.04rem;
      padding: 0.02rem;
      font-size: 0.1rem;
      line-height: 1;
    }
    & .text-2 {
      font-weight: bold;
      height: 0.36rem;
      line-height: 0.18rem;
    }

    & .type {
      font-weight: lighter;
      font-size: 0.11rem;
    }
    & .estimate {
      font-size: 0.1rem;
      margin-left: 0.1rem;
      color: var(--base-color);
      & span {
        line-height: 1;
      }
      & >>> span {
        font-size: inherit;
      }
    }
  }
</style>
<template>
  <div
    class="goods-info"
    v-if="exist"
  >
    <div class="flex-center-center">
      <img
        :src="imgSize(item.goods_image_url || item.image_url || item.goods_img_360, 'n2')"
        alt=""
      />
      <div class="flex-1">
        <div class="flex text-2">
          <template v-if="hasPromotion(item)">
            <span
              class="flag"
              v-for="(v,k) in item.goods_promotion_name"
              :key="k"
            >{{ v }}</span>
          </template>
          {{ item.goods_name }}</div>
        <div class="type">{{ item.goods_spec }}</div>
        <div class="flex-between">
          <div class="flex-left-bottom">
            <my-coin
              :coin="item.goods_price"
              :convertCoin="item.goods_usd_price"
              :style="{fontSize:'0.15rem'}"
              v-if="item.goods_price"
            />
            <div class="flex-center-center estimate" v-if="item.estimatePrice">
              <span>预估到手价：</span>
              <my-coin :coin="item.estimatePrice"/>
            </div>
            <div class="flex-center-center estimate" v-if="item.goods_pay_price">
              <span>实付金额：</span>
              <my-coin :coin="item.goods_pay_price"/>
            </div>
          </div>
          <span v-if="isLogicDot"><my-coin
            :coin="item.goods_marketprice"
            style="text-decoration: line-through;"
          /></span>
          <span v-else-if="item.goods_num">&times;{{ item.goods_num }}</span>
        </div>
      </div>
    </div>
    <slot/>
  </div>
</template>
<script>
  import Coin from "./Coin/Index.vue"
  import { siteConfig } from "@/config"
  import MyCoin from "@/components/Coin.vue"
  import goods from "@/mixins/goods"

  const { isLogicDot = false } = siteConfig
  export default {
    name: "MyGoods",
    mixins: [goods],
    props: {
      item: {
        type: Object,
        required: true
      }
    },
    components: {
      MyCoin,
      [Coin.name]: Coin
    },
    computed: {
      exist () {
        return Object.keys(this.item).length > 1
      }
    },
    data () {
      return {
        isLogicDot
      }
    }
  }
</script>
