<style lang="postcss" scoped>
  .title {
    padding: 0.1rem;
    text-align: center;
    font-size: 0.13rem;
    font-weight: bold;
  }
  .content {
    padding: 0.12rem;
  }
</style>
<template>
  <my-model-bottom :show="show" @close="close">
    <div class="title">{{title}}</div>
    <div class="content" v-html="content"/>
  </my-model-bottom>
</template>
<script>
  import MyModelBottom from '@/components/ModelBottom'
  export default {
    components: { MyModelBottom },
    props: {
      title: {
        type: String,
        default: ''
      },
      content: {
        type: String,
        default: ''
      },
      visible: {
        type: Boolean
      }
    },
    data () {
      return {
        show: false
      }
    },
    methods: {
      close () {
        this.$el.parentNode.removeChild(this.$el)
      }
    },
    created () {
      this.$nextTick(() => {
        this.show = true
      })
    }
  }
</script>
