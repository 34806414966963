import { axios as $http } from "@/plugins/axios"
import { postData } from "@/plugins/postData"
import { Toast } from "mint-ui"
import { Confirm } from "@/components"
import deepCopy from "@/utils/deepCopy"
import { siteConfig } from "@/config"

const { dragLocation = false, disabledLocalCart = false } = siteConfig
export const CART_ADD = "CART_ADD"
export const CART_DEL = "CART_DEL"
export const CSRT_EDIT = "CSRT_EDIT"
export const CART_LIST = "CART_LIST"

export default {
  state: {
    localCart: [],
    localList: [],
    cartCount: 0,
    serverList: []
  },
  getters: {
    cartTotal (state) {
      let exactLocalListNum = 0
      if (state.localList.length && !disabledLocalCart) {
        state.localList.forEach(val => {
          if (Array.isArray(val.goods)) {
            val.goods.forEach(v => {
              exactLocalListNum += v.goods_num * 1
            })
          }
        })
        return (Number.isInteger(state.cartCount) ? state.cartCount : 0) + exactLocalListNum
      }
      return state.cartCount
    },
    listTotal (state) {
      if (disabledLocalCart) {
        return state.serverList
      }
      const localList = JSON.parse(JSON.stringify(state.localList)).map(store => {
        store.goodsArr = store.goods
        return store
      })
      return [...localList, ...state.serverList]
    }
  },
  mutations: {
    cartCount (state, payload) {
      state.cartCount = payload
    },
    addToLocal (state, payload) {
      if (disabledLocalCart) {
        return
      }
      const isInCart = state.localCart.some(val => val.goods_id === payload.goods_id)
      if (!isInCart) {
        state.localCart.unshift(payload)
      } else {
        state.localCart.forEach((val, key) => {
          if (val.goods_id === payload.goods_id) {
            val.quantity = val.quantity * 1 + payload.quantity
          }
        })
      }
    },
    removeLocalCart (state) {
      state.localCart = []
    },
    removeLocalCartById (state, payload) {
      const ids = payload.split(",")
      state.localCart = state.localCart.filter(val => ids.indexOf(val.goods_id) === -1)
      this.dispatch("goodInfobyId")
    },
    editLocal (state, payload) {
      state.localCart = state.localCart.map(val => {
        if (val.goods_id === payload.cart_id) {
          val.quantity = payload.quantity
        }
        return val
      })
      this.dispatch("goodInfobyId")
    },
    listToServer (state, payload) {
      state.serverList = payload
    },
    listToLocal (state, payload) {
      state.localList = payload
    }
  },
  actions: {
    cartNumAsync ({ commit, state }, payload) {
      if (window._config.config && window._config.config.isJdUnion) {
        return
      }
      if (this.throttle) {
        return
      }
      this.throttle = true
      setTimeout(() => {
        this.throttle = false
      }, 300)
      if (payload) {
        this.getCartPayload = payload
      }
      $http.get("", {
        params: {
          act: "member_cart",
          op: "cart_count",
            ...this.getCartPayload
        },
        showMessage: false,
        loading: false
      }).then(res => {
        const { datas: { cart_count: cartCount = 0 } } = res
        commit("cartCount", Number(cartCount))
      }).catch(_e => {
        commit("cartCount", 0)
      })
    },
    addCartAsync ({ dispatch, commit }, payload) {
      $http.post("", postData(payload), {
        params: {
          act: "member_cart",
          op: "cart_add"
        },
        showLoginForm: disabledLocalCart,
        loading: false
      }).then(res => {
        const { datas } = res
        if (datas === "1") {
          if (window.window.toastHandle) {
            window.toastHandle.close()
          }
          window.toastHandle = Toast({
            message: "加入购物车成功！",
            position: "bottom"
          })
          dispatch("cartNumAsync")
        }
      }).catch(e => {
        // 禁用离线购物车
        if (disabledLocalCart) {
          return
        }
        const { login } = e
        // // 未登录
        if (login * 1 === 0) {
          commit("addToLocal", payload)
          dispatch("goodInfobyId")
          if (window.window.toastHandle) {
            window.toastHandle.close()
          }
          window.toastHandle = Toast({
            message: "加入购物车成功！",
            position: "bottom"
          })
        }
      })
    },
    addCartBatchAsync ({ commit, dispatch, state }, payload) {
      let cartlist
      if (payload) {
        cartlist = payload
      } else {
        if (!state.localCart.length) {
          return Promise.reject(new Error("no need"))
        }
        cartlist = state.localCart.map(val => [val.goods_id, val.quantity].join(",")).join("|")
      }
      return $http.post("", postData({
        cartlist
      }), {
        params: {
          act: "member_cart",
          op: "cart_batchadd"
        }
      }).then(() => {
        commit("removeLocalCart", [])
        commit("listToLocal", [])
        dispatch("cartNumAsync")
      })
    },
    deleteCartsAsync ({ dispatch, commit, state }, payload) {
      const { cart_id: cartIds } = payload || {}
      const arr = cartIds.split(",")
      if (arr.length === 0) {
        return false
      }
      const message = `确定要删除${arr.length === 1 ? "该" : "这些"}商品么?`
      Confirm({
        title: "提示",
        message,
        callback: status => {
          if (status === "confirm") {
            // 为local类型
            if (state.localList.length) {
              commit("removeLocalCartById", payload.cart_id)
              return
            }
            const { callback, ...params } = payload
            $http.post("", postData(params), {
              params: {
                act: "hapi",
                op: "delCart"
              }
            }).then(({ datas }) => {
              if (datas * 1 === 1) {
                callback && callback()
                dispatch("getCartAsync")
              }
            })
          }
        }
      })
    },
    editCartAsync ({ dispatch, commit, state }, payload) {
      if (state.localCart.length) {
        commit("editLocal", payload)
        return
      }
      $http.post("", postData(payload), {
        params: {
          act: "member_cart",
          op: "cart_edit_quantity"
        },
        loading: false
      }).then(() => {
        dispatch("getCartAsync")
      })
    },
    getCartAsync ({ state, dispatch, commit }, payload) {
      if (payload) {
        this.getCartPayload = payload
      }
      $http.get("", {
        params: {
          act: "member_cart",
          op: "cart_list",
          ...this.getCartPayload
        },
        showLoginForm: disabledLocalCart
      }).then(res => {
        const { datas: { cart_list: cartList = [] } } = res
        // 整理数据，当有x件xx元活动时，需要按活动ID对商品进行归组
        cartList.forEach(item => {
          const arr = []
          const activity = {}
          item.has_stock = false
          item.goods.forEach((val, index) => {
            if (val.has_stock) {
              item.has_stock = true
            }
            // 添加预估到手价字段
            val.estimatePrice = 0
            val.estimatePriceActivityName = ""
            // 以活动归类商品
            if (val.activity_id) {
              if (activity[val.activity_id] === undefined) {
                activity[val.activity_id] = arr.length
                const temp = []
                temp.brandName = val.brandName
                temp.activity_id = val.activity_id
                temp.has_stock = !!val.has_stock
                // 以X件X元归子类
                if (val.akc_NX) {
                  const temp2 = [val]
                  temp2.actName = "* 任选" + val.nxPriceName
                  temp2.actNameBase = val.nxPriceName
                  temp.push(temp2)
                } else {
                  temp.push(val)
                }
                arr.push(temp)
              } else {
                if (val.has_stock) {
                  arr[activity[val.activity_id]].has_stock = true
                }
                if (val.akc_NX) {
                  // 查询是否存在X件X元子类
                  if (arr[activity[val.activity_id]].some(v => v.actNameBase === val.nxPriceName)) {
                    // 存在则将商品放入对应的数组中
                    for (const nx of arr[activity[val.activity_id]]) {
                      if (nx.actNameBase === val.nxPriceName) {
                        nx.push(val)
                        break
                      }
                    }
                  } else {
                    // 不存在则新增一个数组
                    const temp2 = [val]
                    temp2.actName = "* 任选" + val.nxPriceName
                    temp2.actNameBase = val.nxPriceName
                    arr[activity[val.activity_id]].push(temp2)
                  }
                } else {
                  arr[activity[val.activity_id]].push(val)
                }
              }
            } else {
              arr.push(val)
            }
          })
          item.goodsArr = arr
        })
        // 禁用离线购物车
        if (disabledLocalCart) {
          commit("listToServer", cartList)
          commit("listToLocal", [])
          dispatch("cartNumAsync")
          return
        }
        // 取到数据说明登录成功，需要上报本地购物车数据
        dispatch("addCartBatchAsync").then(() => {
          dispatch("getCartAsync")
        }).catch(() => {
          commit("listToServer", cartList)
          commit("listToLocal", [])
          dispatch("cartNumAsync")
        })
      }).catch(e => {
        const { login } = e
        if (login * 1 === 0) {
          commit("listToServer", [])
          if (!disabledLocalCart) {
            dispatch("goodInfobyId")
          }
        }
      })
    },
    goodInfobyId ({ state, commit, dispatch }) {
      if (!state.localCart.length) {
        commit("listToLocal", [])
        commit("removeLocalCart", [])
        dispatch("cartNumAsync")
        return
      }
      // const ids = state.localCart.map(val => val.goods_id).join(',')
      // 需要判断ids是否都存在localList
      const ids = []
      state.localCart.forEach(val => {
        if (state.localList.length === 0) {
          ids.push(val.goods_id)
          return
        }
        let isFind = false
        state.localList.forEach(store => {
          if (Array.isArray(store.goods)) {
            store.goods.forEach(goods => {
              if (goods.cart_id === val.goods_id) {
                isFind = true
                goods.goods_num = val.quantity
              }
            })
          }
        })
        if (isFind === false) {
          ids.push(val.goods_id)
        }
      })
      // 反向判断localList多余的商品删除
      const temp = deepCopy(state.localList)
      const cartIds = state.localCart.map(val => val.goods_id)
      state.localList.forEach((store, key) => {
        if (Array.isArray(store.goods)) {
          store.goods.forEach((goods, k) => {
            if (cartIds.includes(goods.goods_id) === false) {
              temp[key].goods.splice(k, 1)
            }
          })
        }
      })
      commit("listToLocal", temp)
      if (ids.length === 0 && state.localList.length > 0) {
        return
      }
      $http.get("", {
        params: {
          act: "hapi",
          op: "get_goods_byId",
          ids: ids.join(","),
          // 联盟定位校验库存接口
          stock: dragLocation ? 1 : undefined
        },
        loading: false
      }).then(res => {
        const { datas: { goods_list: list = [] } } = res
        const temp = deepCopy(state.localList)
        const validLocalCart = []
        state.localCart.forEach(val => {
          const curr = list.filter(v => v.goods_id === val.goods_id)[0]
          if (!curr) {
            return
          }
          if (curr.goods_marketprice > 0) {
            delete curr.goods_marketprice
          }
          if (curr.goods_promotion_price > 0) {
            delete curr.goods_promotion_price
          }
          validLocalCart.push(val)
          const goods = {
            ...curr,
            cart_id: curr.goods_id,
            goods_num: val.quantity,
            goods_total: (curr.goods_price * val.quantity).toFixed(2),
            has_stock: true
          }
          let storeIndex
          temp.forEach((v, k) => {
            if (v.store_id === curr.store_id) {
              storeIndex = k
            }
          })
          if (storeIndex === undefined) {
            temp.push({
              store_id: curr.store_id,
              store_name: curr.store_name,
              goods: [goods]
            })
          } else {
            temp[storeIndex]["goods"].push(goods)
          }
        })
        commit("listToLocal", temp)
        dispatch("cartNumAsync")
      })
    }
  }
}
