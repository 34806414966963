<style
  lang="postcss"
  scoped
>
  .top {
    top: 80vh;
  }

  .global-service {
    background: #fff;
    line-height: 1;
    border-radius: 0.22rem;
    width: 0.44rem;
    padding: 0.05rem 0;
    text-align: center;
    box-shadow: 0 0 10px 1px #ddd;

    & .item {
      padding: 0.1rem 0;
      color: #333;

      &:last-child:before {
        display: none;
      }
    }

    & .iconfont {
      display: block;
      font-size: 0.2rem;
      line-height: 1;
    }

    & span {
      font-size: 0.1rem;
      line-height: 1;
    }
  }
</style>
<template>
  <my-drag
    class="top"
    v-if="homepage || userpage"
  >
    <div class="flex flex-column global-service">
      <a
        :href="homepage"
        class="border-bm item"
        v-if="homepage"
      >
        <span class="iconfont icon-homepage"></span>
        <span>主页</span>
      </a>
      <a
        :href="userpage"
        class="border-bm item"
        v-if="userpage"
      >
        <span class="iconfont icon-jifen"></span>
        <span>资产</span>
      </a>
    </div>
  </my-drag>
</template>
<script>
  import MyDrag from "./Drag"

  export default {
    name: "MyHomeJingjiniao",
    components: {
      MyDrag
    },
    data () {
      return {
        homepage: "",
        userpage: ""
      }
    },
    created () {
      this.homepage = this.$cookie.get("homepage")
      if (this.homepage) {
        this.homepage = decodeURIComponent(this.homepage)
      }
      this.userpage = this.$cookie.get("userpage")
      if (this.userpage) {
        this.userpage = decodeURIComponent(this.userpage)
      }
      if (this.homepage || this.userpage) {
        window.eventBus.$emit("hideThirdHome")
      }
    }
  }
</script>
