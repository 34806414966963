import { render, staticRenderFns } from "./HomeJingjiniao.vue?vue&type=template&id=74c33972&scoped=true&"
import script from "./HomeJingjiniao.vue?vue&type=script&lang=js&"
export * from "./HomeJingjiniao.vue?vue&type=script&lang=js&"
import style0 from "./HomeJingjiniao.vue?vue&type=style&index=0&id=74c33972&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74c33972",
  null
  
)

export default component.exports