<template>
  <CommentJd :id="jd" v-if="jd"/>
  <CommentSuning :id="suning" v-else-if="suning"/>
  <CommentBase :id="id" :list="goods_eval_list" :info="goods_evaluate_info" v-else/>
</template>
<script>
  import deepCopy from '../../utils/deepCopy'

  export default {
    name: 'MyComment',
    components: {
      'CommentBase': () => import('./Base.vue'),
      'CommentJd': () => import('./Jd.vue'),
      'CommentSuning': () => import('./Suning.vue')
    },
    props: {
      attr: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        jd: undefined,
        suning: undefined,
        id: undefined,
        goods_eval_list: {},
        goods_evaluate_info: {}
      }
    },
    watch: {
      attr (curr) {
        const temp = deepCopy(curr)
        Object.assign(this.$data, temp)
      }
    }
  }
</script>
