<template>
  <div v-if="appStatus && login === -1"></div>
  <div v-else-if="appStatus && login === 0">
    <MyProtocol v-if="$route.path === '/protocol'"/>
    <MyLoin v-else/>
  </div>
  <div v-else>
    <slot/>
  </div>
</template>
<script>
  import { siteConfig } from "@/config"

  const { private: appStatus = false } = siteConfig

  export default {
    name: "MyPrivate",
    components: {
      MyLoin: () => import("../pages/Login"),
      MyProtocol: () => import("../pages/Protocol")
    },
    data () {
      return {
        appStatus,
        login: -1
      }
    },
    methods: {
      initiation () {
        this.$http.get("", {
          params: {
            act: "member_index"
          },
          showMessage: false
        }).then(_res => {
          this.login = 1
        }).catch(_e => {
          this.$emit("pageLoad")
          this.login = 0
        })
      }
    },
    created () {
      if (appStatus) {
        this.initiation()
      }
    }
  }
</script>
