var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.shutdownSite)?_c('div',{class:{
  'has-nav': _vm.useNav && !_vm.isKidsWant,
  'theme-2': _vm.$root['theme'] === 2,
  'theme-3': _vm.$root['theme'] === 3,
  'theme-4': _vm.$root['theme'] === 4,
  'kids-want-app': _vm.$root.nativeBar.isApp
},attrs:{"id":"app"}},[(_vm.useNav)?[(_vm.isKidsWant)?_c('kids-want-nav'):_c('my-nav')]:_vm._e(),_c('my-private',{on:{"pageLoad":_vm.removeLoading}},[(_vm.showGlobalBackButton)?_c('float-back-button'):_vm._e(),_c('transition',{attrs:{"name":_vm.direction['direction']},on:{"after-enter":_vm.removeLoading}},[_c('keep-alive',{attrs:{"include":"SearchList,TabView,PageVenue,DangQi"}},[_c('router-view')],1)],1),_c('my-phone'),_c('my-home-page'),_c('my-home-jingjiniao'),_c('kids-want-float'),_c('drag-location'),(_vm.openOrderNotification)?_c('order-notification'):_vm._e()],1),_c('tool-bar'),(_vm.loading.loading)?_c('div',{staticClass:"mint-indicator-wrapper",staticStyle:{"padding":"0.15rem","width":"auto"}},[_c('mt-spinner',{attrs:{"type":"double-bounce"}})],1):_vm._e()],2):_c('div',{staticClass:"site-lock"},[_vm._v(" 系统维护中！ ")])
}
var staticRenderFns = []

export { render, staticRenderFns }