<style lang="postcss" scoped>
  .footer {
    position: fixed;
    bottom: 0;
    height: 0.44rem;
    width: inherit;
    z-index: 999;
    text-align: center;
    background: #fff;
    padding-bottom: env(safe-area-inset-bottom);
    transition: 0.25s cubic-bezier(0.27, 1.21, 1, 1);
    &.toolbar {
      transform: translateY(-0.44rem);
    }
  }
</style>
<template>
  <footer class="footer" :class="{toolbar: toolBar.show && toolBar.isNeed}">
    <slot name="content"></slot>
  </footer>
</template>
<script>
  import { mapState } from 'vuex'

  export default {
    name: 'MyFooter',
    computed: {
      ...mapState(['toolBar'])
    }
  }
</script>
