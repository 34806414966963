export default {
  install (Vue) {
    Vue.prototype.getQuery = function (url, name) {
      let reg = new RegExp('(?:^|\\?|&)' + name + '=([^&]+)', 'g')
      let arr = reg.exec(url)
      if (arr) {
        return arr[1] || null
      }
    }
  }
}
