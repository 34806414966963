export const SETTOOLBARSTATE = 'SETTOOLBARSTATE'

export default {
  state: {
    show: false,
    isNeed: false
  },
  mutations: {
    [SETTOOLBARSTATE] (state, payload) {
      Object.assign(state, payload)
      return state
    }
  }
}
