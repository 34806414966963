import { siteConfig } from '../config'

const { themeType = 1 } = siteConfig

if (themeType === 2) {
  // eslint-disable-next-line no-unused-expressions
  import('../css/theme-css/2.css')
  const tag = document.createElement('script')
  tag.src = '//at.alicdn.com/t/font_2015647_cujpeoq86r7.js'
  tag.onload = function () {
    setTimeout(() => {
      const svg = document.getElementsByTagName('svg')[0]
      const svgSombolPath = svg.getElementsByTagName('path')
      for (let i = 0; i < svgSombolPath.length; i++) {
        svgSombolPath[i].style.fill = 'currentColor'
      }
    }, 10)
  }
  document.head.appendChild(tag)
}

if (themeType === 3) {
  import('../css/theme-css/3.css').then()
}

// 孩子王主题
// if (themeType === 4) {
//   import('../css/theme-css/4.css').then()
// }
