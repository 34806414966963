<style
  lang="postcss"
  scoped
>

  .ticket-item {
    min-height: 0.96rem;
    background: #fff;
    box-shadow: 1px 1px 3px #f1f1f1;
    overflow: hidden;
    position: relative;
    padding-left: 1.08rem;

    & .price {
      width: 1.08rem;
      height: 100%;
      background: linear-gradient(135deg, var(--base-color), var(--base-color-alpha-70));
      position: absolute;
      color: #fff;
      left: 0;

      &:before {
        content: '';
        display: block;
        width: 0.06rem;
        height: 100%;
        position: absolute;
        left: -0.03rem;
        top: 0;
        z-index: 99;
        background-image: radial-gradient(circle, #f8f8f8 70%, transparent 70%, transparent 100%);
        background-size: 0.06rem 0.06rem;
        background-repeat: repeat-y;
      }

      & .num {
        font-size: 0.4rem;
        color: #fff;
      }
    }

    & .image {
      background: none;

      &:before {
        display: none;
      }

      & img {
        width: 100%;
      }
    }

    & .explain {
      height: 100%;
      width: 100%;
      padding: 0.08rem;
      box-sizing: border-box;
      overflow: hidden;

      & .tit {
        width: 100%;
        font-weight: bold;
      }

      & .flag {
        background: var(--base-color);
        line-height: 1;
        font-size: 0.1rem;
        padding: 0.02rem 0.04rem;
        color: #fff;
        border-radius: 0.1rem;
        margin-right: 0.04rem;
        font-weight: normal;
      }

      & .desc {
        color: #999;
        width: 100%;
        text-align: left;
        font-weight: normal;
        min-height: 0.38rem;
      }

      & .time {
        width: 100%;
        display: block;
        font-size: 0.1rem;
        color: #2f2f2f;
        font-family: 'NUM_Light';
        text-align: left;
      }

      & .limit {
        margin-top: 0.06rem;
        padding-top: 0.08rem;
        font-size: smaller;
        width: 100%;

        & .dark {
          color: #999;
          display: none;
        }

        & .icon-enter {
          font-size: 0.12rem;
        }

        & .emptys {
          width: 0.72rem;
        }
      }
    }

    & .discount {
      & .number {
        font-size: 0.4rem;
        font-family: 'NUM_Regular', sans-serif;
        line-height: 1;
      }
    }
  }
</style>
<template>
  <div class="flex-left-center ticket-item">
    <div
      v-if="item.t_image"
      class="flex-center-center price image"
    >
      <img
        :src="item.t_image"
        :alt="item.t_title"
      >
    </div>
    <template v-else>
      <div class="flex-column flex-center-center price">
        <my-coin
          :coin="item.t_price"
          class="num"
          v-if="item.t_price * 1 >= 1"
        />
        <div
          class="flex-center-bottom discount"
          v-else
        >
          <span class="number">{{ item.t_price * 10 }}</span>
          <span>折券</span>
        </div>
        <p v-if="item.t_limit * 1">满{{ item.t_limit * 1 }}元可用</p>
        <div
          class="btn-placeholder"
          v-if="$slots.default"
        />
      </div>
      <div class="flex-1 flex-column flex-between explain">
        <div class="flex-left-center tit">
          <span class="flag">{{ typeName }}</span>
          <span>{{ item.t_title }}</span>
        </div>
        <p
          class="flex-1 desc"
          :class="{'text-1': !showMore}"
          @click="showMore = !showMore"
          v-html="lineFeed(item.t_desc)"
        />
        <time
          class="time"
          v-if="item.t_start_date && item.t_end_date"
        >{{ item.t_start_date | time }} - {{ item.t_end_date | time }}
        </time>
        <div
          class="border-tp flex-between limit"
          v-if="item.t_eachlimit || $slots.relay"
        >
          <div
            class="eachlimit"
            v-if="item.t_eachlimit"
          >可领{{ item.t_eachlimit }}次
          </div>
          <slot name="relay"/>
        </div>
      </div>
    </template>
    <slot/>
  </div>
</template>
<script>
  import Coin from './Coin/Index.vue'

  export default {
    name: 'MyTicket',
    components: {
      [Coin.name]: Coin
    },
    data () {
      return {
        showMore: true
      }
    },
    props: {
      item: {
        type: Object,
        required: true
      },
      typeName: {
        type: String,
        default: '店铺优惠券'
      }
    },
    filters: {
      time (stamp) {
        const t = new Date(stamp * 1000)
        const date = t.toLocaleDateString().replace(/\//g, '.')
        const time = t.toTimeString().split(' ')[0]
        return `${date} ${time}`
      }
    },
    methods: {
      lineFeed (str) {
        return str ? str.replace(/[\r\n\s]+/g, '<br/>') : ''
      }
    }
  }
</script>
