export const postData = function (res) {
  let data = new URLSearchParams()
  Object.keys(res).forEach(key => {
    if (res[key]) {
      data.append(key, res[key])
    }
  })
  return data
}

export default {
  install (Vue) {
    Vue.prototype.postData = postData
  }
}
