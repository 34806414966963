<style lang="postcss" scoped>
  .go-top {
    position: fixed;
    right: 0.1rem;
    bottom: 0.64rem;
    z-index: 999;
    width: 0.4rem;
    height: 0.4rem;
    line-height: 0.44rem;
    text-align: center;
    margin-bottom: env(safe-area-inset-bottom);

    & .iconfont {
      font-size: 0.34rem;
      opacity: 0.5;
      background: #fff;
      border-radius: 100%;
    }
  }

  .go-home {
    bottom: 1rem;

    & .iconfont {
      background: rgba(0, 0, 0, 0.7);
      font-size: 0.22rem;
      color: #fff;
      padding: 0.04rem;
    }
  }
</style>
<template>
  <div>
    <div class="go-top go-home" @click="home" v-if="showBackHome && isNeed">
      <span class="iconfont icon-homepage"></span>
    </div>
    <div class="go-top" @click="top" v-show="show">
      <span class="iconfont icon-top"></span>
    </div>
  </div>

</template>
<script>
  import { siteConfig } from '../config'

  const { showBackHome = false } = siteConfig

  export default {
    name: 'MyGoTop',
    data () {
      return {
        show: false,
        showBackHome
      }
    },
    computed: {
      isNeed () {
        const arr = ['index.html', 'menu', 'cart', 'order', 'detail']
        return arr.indexOf(this.$route.name) === -1
      }
    },
    methods: {
      top () {
        window.scrollTo(0, 0)
      },
      scroll () {
        let scrollTop = document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset
        this.show = scrollTop > screen.availHeight * 2
      },
      home () {
        this.$router.push({
          path: '/'
        })
      }
    },
    created () {
      document.addEventListener('scroll', this.scroll)
    }
  }
</script>
