import Vue from 'vue'
import Confirm from './Confirm.vue' // 引入组件
let ConfirmConstructor = Vue.extend(Confirm) // 返回一个“扩展实例构造器”

let ConfirmFun = (options = {}) => {
  let el = document.createElement('div')
  document.body.appendChild(el)
  if (window.ConfirmHandle) {
    window.ConfirmHandle.visible = false
  }
  window.ConfirmHandle = new ConfirmConstructor({
    propsData: {
      ...options,
      visible: true
    }
  }).$mount(el)
}

export default ConfirmFun
