<style
  lang="postcss"
  scoped
>
  .voucher {
    & .ling {
      font-size: smaller;
      padding: 0.02rem 0.06rem;
      border-radius: 0.3rem;
      margin-right: 0.08rem;
      display: inline;
      color: var(--base-color);
      background: var(--base-color-lightness-95);
    }

    & .note {
      position: relative;
      font-size: 0.1rem;
      margin-right: 0.08rem;
      color: var(--base-color);
      padding: 0.02rem 0.04rem;
      border: 1px solid var(--base-color);
      border-radius: 0.04rem;
      display: inline;

      & .split {
        padding: 0.03rem;
        position: relative;
      }

      & .status.done {
        color: var(--base-color-alpha-40);
      }
    }

    & .voucher-model {
      padding-bottom: env(safe-area-inset-bottom);
      background: #f8f8f8;

      & .icon-close {
        position: absolute;
        right: 0.08rem;
        top: 0.1rem;
        font-size: 0.16rem;
      }

      & .top {
        line-height: 0.4rem;
        font-size: 0.13rem;
        font-weight: bold;
        text-align: center;
      }

      & .middle {
        overflow: scroll;
        -webkit-overflow-scrolling: touch;
        height: 4rem;
        padding-bottom: 0.1rem;
        margin-bottom: env(safe-area-inset-bottom);
      }

      & .con {
        padding: 0.08rem;
      }

      & .list {
        padding: 0 0.08rem;

        & .cell {
          margin-bottom: 0.08rem;
        }
      }

      & .empty {
        color: #999;
        text-align: center;

        & img {
          width: 0.64rem;
          height: 0.64rem;
          margin: 0 auto;
        }
      }

      & .btn-save {
        position: absolute;
        right: 0.08rem;
        bottom: 0.08rem;
        z-index: 99;
        background: linear-gradient(135deg, var(--base-color), var(--base-color-blackness-20));
      }

      & .num {
        position: absolute;
        right: 0.08rem;
        bottom: 0.08rem;
        z-index: 99;
      }
    }
  }
</style>
<template>
  <div
    class="voucher"
    v-if="enableVoucher"
  >
    <div
      class="box"
      @click="isShow = true"
      v-if="allowReceive.length"
    >
      <div class="flex-between-top con">
        <span class="flag">优惠</span>
        <div class="scroll-x flex-1 text">
            <div class="scroll-body">
              <span class="ling">领券</span>
              <span
                class="note"
                v-for="item in allowReceive"
                :key="item.t_id"
              >
            <span class="tit">满{{ item.t_limit * 1 }}减{{ item.t_price * 1 }}</span>
            <span class="split">&middot;</span>
            <span
              class="status"
              :class="{done: status(item.t_id)}"
            >{{ status(item.t_id) ? "已领" : "领取" }}</span>
          </span>
          </div>
        </div>
        <span class="iconfont icon-more"></span>
      </div>
    </div>
    <mt-popup
      v-model="isShow"
      position="bottom"
    >
      <div
        class="voucher-model"
        :style="styles"
      >
        <span
          class="iconfont icon-close"
          @click="isShow = false"
        ></span>
        <div class="top">优惠券</div>
        <div
          class="middle"
          ref="middle"
          @touchmove="handleTouchmove"
        >
          <div
            class="con"
            v-if="allowReceive.length"
          >可领优惠券
          </div>
          <div
            class="list"
            v-if="allowReceive.length"
          >
            <my-ticket
              class="cell"
              v-for="item in allowReceive"
              :item="item"
              :key="item.t_id"
            >
              <mt-button
                type="danger"
                size="small"
                class="btn-save"
                @click="saveVoucher(item)"
              >点击领券
              </mt-button>
            </my-ticket>
          </div>
          <div
            class="empty"
            v-else
          >
            <img src="../assets/no-ticket.svg"/>
            <p>暂无优惠券可领</p>
          </div>
          <template>
            <div
              class="con"
              v-if="alreadyReceive.length"
            >已领取优惠券
            </div>
            <div
              class="list"
              v-if="alreadyReceive.length"
            >
              <my-ticket
                class="cell"
                v-for="item in alreadyReceive"
                :item="item"
                :key="item.voucher_t_id"
              >
                <p
                  class="num"
                  v-if="item.num * 1 > 1"
                >{{isKidsWant? '第':'&times;'}}{{ item.num }}张
                </p>
              </my-ticket>
            </div>
          </template>
        </div>
      </div>
    </mt-popup>
  </div>
</template>
<script>
  import { Popup, Button } from 'mint-ui'
  import Ticket from './Ticket'
  import voucherList from '../mixins/voucher'
  import { siteConfig } from '@/config'

  const { isKidsWant = false } = siteConfig

  export default {
    name: 'MyVoucher',
    components: {
      [Popup.name]: Popup,
      [Ticket.name]: Ticket,
      [Button.name]: Button
    },
    props: {
      goodsId: {
        type: String,
        default: ''
      },
      venueId: {
        type: String,
        default: ''
      }
    },
    data () {
      return {
        isShow: false,
        isKidsWant,
        styles: {}
      }
    },
    methods: {
      status (tId) {
        let text = false
        try {
          this.alreadyReceive.forEach(val => {
            if (val.t_id === tId) {
              text = true
              throw new Error('find')
            }
          })
        } catch (e) {}
        return text
      },
      handleTouchmove (e) {
        if (this.$refs.middle.scrollHeight <= this.$refs.middle.clientHeight) {
          e.stopPropagation()
          e.preventDefault()
        }
      }
    },
    watch: {
      isShow () {
        this.styles.width = `${document.body.clientWidth}px`
      }
    },
    mixins: [voucherList],
  }
</script>
