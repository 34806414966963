<style
  lang="postcss"
  scoped
>
  .banner {

    & .pagination {
      position: absolute;
      bottom: 0.08rem;
      text-align: center;
      z-index: 99;
    }

    & img {
      width: 100%;
      min-height: 100%;
    }
  }
</style>
<template>
  <swiper
    :options="swiperOption"
    class="banner"
    v-if="list && list.length"
    :style="formatStyle(userStyle.box)"
    data-style-name="box"
  >
    <swiper-slide
      v-for="val in list"
      :key="val.id"
      style="width: 100%"
    >
      <my-link
        :link="val.link || {}"
        style="width: 100%"
      >
        <img
          :data-src="imgSize(val.banner)"
          src=""
          class="swiper-lazy"
          data-style-name="banner"
          :style="formatStyle(userStyle.banner)"
        />
      </my-link>
      <div class="swiper-lazy-preloader"></div>
    </swiper-slide>
    <div
      class="pagination"
      slot="pagination"
    ></div>
  </swiper>
  <my-module-preview
    :code="$attrs.code"
    v-else
  />
</template>
<script>
  import 'swiper/dist/css/swiper.css'
  import { swiper, swiperSlide } from 'vue-awesome-swiper'
  import MyLink from './Link'
  import MyModulePreview from '@/components/MyModulePreview'

  export default {
    name: 'MySwiper',
    components: {
      MyModulePreview,
      swiper,
      swiperSlide,
      MyLink
    },
    props: {
      list: {
        type: Array
      },
      userStyle: {
        type: Object,
        default: function () {
          return {}
        }
      }
    },
    data () {
      return {
        swiperOption: {
          loop: true,
          autoplay: true,
          pagination: {
            el: '.pagination'
          },
          lazy: {
            loadPrevNext: true
          }
        }
      }
    }
  }
</script>
