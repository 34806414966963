<style lang="postcss">
  /*红包样式*/
  .redpack {

    & .price,
    & .btn-save {
      background: linear-gradient(135deg, var(--base-color), var(--base-color-alpha-50)) !important;
    }

    & .flag {
      background: var(--base-color) !important;
    }

  }
</style>
<template>
  <my-ticket class="redpack" :item="item" :typeName="'平台红包'">
    <slot></slot>
  </my-ticket>
</template>
<script>
  import Ticket from './Ticket'

  export default {
    name: 'MyRedPack',
    components: {
      [Ticket.name]: Ticket
    },
    props: {
      item: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        info: {}
      }
    },
    watch: {
      item (curr) {
        this.info = curr
      }
    }
  }
</script>
