import { siteConfig } from '@/config'

const { isJdUnion = false } = siteConfig

const jumpJdDetailBeforeData = 'jumpJdDetailBeforeData'

/**
 * 跳转到其他域名时候，点击浏览器后退按钮ios设备会缓存上级页面数据导致页面js不执行，从而引起vue应用bug
 * @param event
 */
window.onpageshow = function(event) {
  if (event.persisted) {
    localStorage.setItem('ios-persisted', 'yes')
    location.reload()
  }
}

export const applyPageData = (this$data) => {
  if (!isJdUnion) {
    return
  }
  const performanceNavigation = window.performance.navigation ||
    window.performance.getEntriesByType('navigation')[0]
  const openType = performanceNavigation.type

  if (openType === 2 || openType === 'back_forward' || localStorage.getItem('ios-persisted') === 'yes') {
    localStorage.removeItem('ios-persisted')
    const pageData = localStorage.getItem(jumpJdDetailBeforeData)

    if (pageData) {
      const { scrollTop, this$data: $data } = JSON.parse(pageData)
      if ($data.identifier < Date.now()) {
        delete $data.identifier
      }
      Object.assign(this$data, $data)

      const step = () => {
        if (!scrollTop) {
          window.scrollTo(0, 1)
          return
        }
        const documentHeight = document.body.scrollHeight || document.documentElement.scrollHeight
        const clientHeight = window.screen.availHeight
        if (scrollTop + clientHeight < documentHeight) {
          window.scrollTo(0, scrollTop)
          return
        }
        window.scrollTo(0, scrollTop)
        requestAnimationFrame(step)
      }
      requestAnimationFrame(step)
      localStorage.removeItem(jumpJdDetailBeforeData)
    }
  }
}

export const setPageData = (data) => {
  const scrollTop = document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset
  localStorage.setItem(jumpJdDetailBeforeData, JSON.stringify({
    scrollTop,
    this$data: data
  }))
}

export const removePageData = () => {
  localStorage.removeItem(jumpJdDetailBeforeData)
}

const pageDataLoadDone = 'pageDataLoadDone'

export const getPageLoadDone = () => {
  if (!isJdUnion) {
    return
  }
  return localStorage.getItem(pageDataLoadDone)
}

export const setPageLoadDone = () => {
  if (!isJdUnion) {
    return
  }
  localStorage.setItem(pageDataLoadDone, true)
}

export const removePageLoadDone = () => {
  if (!isJdUnion) {
    return
  }
  localStorage.removeItem(pageDataLoadDone)
}

export const handleUnionHrefClick = (event, this$data) => {
  if (!isJdUnion) {
    return
  }
  let ele = event.target
  while (ele) {
    if (ele.nodeName.toLowerCase() === 'a') {
      setPageData(this$data)
      return
    }
    ele = ele.parentNode
  }
}
