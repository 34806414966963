import { IS_MINIPROGRAM, IS_WEIXIN } from '../config'
import { axios } from '../plugins/axios'
import { base } from '../router/index'

const wxConfig = _vueRoute => {
  if (window.forbidWeixinConfig) {
    return
  }
  // 首先判断是否为微信浏览器环境
  if (!IS_WEIXIN) {
    return
  }
  if (IS_MINIPROGRAM) {
    return
  }
  if (process.env.NODE_ENV !== 'production') {
    return
  }
  axios.get('', {
    params: {
      act: 'hapi',
      op: 'weixin'
    },
    headers: {
      currURI: window.location.origin + base + _vueRoute.fullPath.slice(1)
    },
    showMessage: false
  }).then(res => {
    const { datas } = res
    const { wx, eventBus } = window
    wx.config({
      ...datas,
      jsApiList: ['hideAllNonBaseMenuItem', 'updateAppMessageShareData', 'updateTimelineShareData', 'showMenuItems'],
      openTagList: ['wx-open-launch-weapp', 'wx-open-launch-app']
    })
    wx.ready(function () {
      wx.hideAllNonBaseMenuItem()
    })
    eventBus.$emit('wxReady')
    // wx.error(function (res) {
    //   console.log(res)
    // })
  }).catch(_ => {})
}

export { wxConfig }
