<style lang="postcss" scoped>
  .card {
    margin: 0.12rem 0 0;
    padding: 0.12rem;
    background: #f8f8f8;
    border-radius: 0.03rem;
    position: relative;

    &:before {
      content: '';
      border-bottom: 0.08rem solid #f8f8f8;
      border-left: 0.08rem solid transparent;
      border-right: 0.08rem solid transparent;
      position: absolute;
      left: 0.16rem;
      top: -0.07rem;
    }

    & .select {
      padding: 0.04rem 0 0.04rem 0.08rem;
      margin: 0 0.08rem;
      border-radius: 0.2rem;

      &:before {
        border-radius: 1rem;
        border-color: #ddd;
      }

      & select {
        background: transparent;
        width: 100%;
      }
    }

    & .iconfont {
      font-size: 0.16rem;
      margin-left: 0.04rem;
    }
  }
</style>
<template>
  <div class="flex-between card" v-if="show">
    <span>选择卡片</span>
    <div class="flex-1 flex-left-center border select">
      <label class="flex-1">
        <select class="border" v-model="currCardNo" @change="valChange">
          <option
            v-for="(val, key) in cardsNo"
            :key="key"
            :value="val"
          >{{val | hideNum}}
          </option>
        </select>
      </label>
      <span class="iconfont icon-down"></span>
    </div>
    <div class="flex-1 flex-right-center">可用余额:
      <my-coin :coin="currCardPd" :style="{marginLeft: '0.04rem'}"/>
    </div>
  </div>
</template>
<script>
  import MyCoin from '../Coin/Index.vue'

  export default {
    name: 'SdsCard',
    components: {
      MyCoin
    },
    props: {
      defaultCardNo: Number,
      cardPd: Number,
      cardsNo: Array,
      paymentCode: String,
      payType: String
    },
    data () {
      return {
        currCardNo: '',
        currCardPd: ''
      }
    },
    computed: {
      show () {
        return /sdspay1?/.test(this.paymentCode) && this.payType === this.paymentCode
      }
    },
    filters: {
      hideNum (val) {
        const str = val.toString()
        return str.slice(0, 4) + '****' + str.slice(-4)
      }
    },
    methods: {
      getCardInfo () {
        this.$http.post('', this.postData({
          cardNo: this.currCardNo
        }), {
          params: {
            act: 'member_buy',
            op: 'get_card_info'
          }
        }).then(res => {
          this.currCardPd = res.datas.card_pd
        })
      },
      valChange () {
        this.getCardInfo()
        this.$emit('input', { card_no: this.currCardNo })
      }
    },
    created () {
      this.currCardNo = this.defaultCardNo
      this.currCardPd = this.cardPd
    }
  }
</script>
