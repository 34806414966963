// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
import WakeupApp from './components/WakeupApp'

// server-work
// import './registerServiceWorker'

// polyfill
import 'promise-polyfill/src/polyfill'
import 'url-search-params-polyfill'
import './utils/theme'

// geolocation
import getPos from './utils/geolocation'

// image preview
import photoPreview from "vue-photo-preview"
import "vue-photo-preview/dist/skin.css"
Vue.use(photoPreview, {history: true, fullscreenEl: false})

// global plugins
import { axios, cookie, imgSize, getQuery, goBack, postData, countDown, formatStyle } from './plugins/index'
import { Lazyload } from 'mint-ui'
import { EVENTBUS, preview, siteConfig } from './config'

// global directives
import './directives'

Vue.use(cookie)
Vue.use(imgSize)
Vue.use(getQuery)
Vue.use(Lazyload, { preLoad: 2 })
Vue.use(postData)
Vue.use(countDown)
Vue.use(axios)
Vue.use(formatStyle)

// 全局通讯bus
window.eventBus = EVENTBUS
const { themeType = 1, isKidsWant = false } = siteConfig

/* eslint-disable no-new */
let app = new Vue({
  el: '#app',
  router,
  store,
  components: { App },
  data () {
    return {
      title: '',
      webSiteName: '商城',
      theme: themeType,
      radio: document.body.clientWidth / 360,
      safeAreaBottomHeight: parseInt(getComputedStyle(document.documentElement).getPropertyValue("--sab")),
      preview: preview,
      nativeBar: {}
    }
  },
  watch: {
    title (curVal, oldVal) {
      if (curVal === oldVal) {
        return
      }
      document.title = curVal || this.webSiteName
      // fixed webview title不更新 hack
      let iframe = document.createElement('iframe')
      iframe.style.visibility = 'hidden'
      iframe.style.width = '1px'
      iframe.style.height = '1px'
      iframe.onload = function () {
        setTimeout(function () {
          document.body.removeChild(iframe)
        }, 10)
      }
      document.body.appendChild(iframe)
    }
  },
  render: h => h(App),
  mounted () {
    getPos.call(this)
  }
})

Vue.use(goBack, app)
// Vue.use(axios, app)

if (isKidsWant) {
  import('@/utils/hzw').then(() => {
    app.nativeBar = { ...window.kidsWantUtils.barHeight }
  })

  const rela = document.getElementById('app')
  const div = document.createElement('div')
  rela.parentNode.insertBefore(div, rela)

  new Vue({
    el: div,
    components: { WakeupApp },
    render: h => h(WakeupApp),
    data () {
      return {
        app
      }
    }
  })
}
