import formatStyle from '../../../local_lib/formatStyle'

export default {
  install (Vue) {
    Vue.mixin({
      methods: {
        formatStyle
      }
    })
  }
}
