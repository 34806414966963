<template>
  <div style="min-height: 2rem">
    <slot></slot>
    <infinite-loading
      :identifier="identifier"
      @infinite="infiniteHandler"
      :distance="300"
      v-if="section.listType === 1 || (section.listType === 2 && section.selectPoolId)"
      style="padding: 0.1rem"
    >
      <div
        slot="spinner"
        class="flex-center-center"
      >
        <mt-spinner type="triple-bounce"></mt-spinner>
        <span>努力加载中...</span>
      </div>
      <div slot="no-results">
        <span v-if="$root.preview.inIframe === false">没有数据！</span>
      </div>
      <div slot="no-more">
        <span v-if="$root.preview.inIframe === false && section.listType === 1">
          已加载完全部数据！
        </span>
      </div>
    </infinite-loading>
    <template v-if="$root.preview.inIframe">
      <div
        class="flex-center-center"
        style="clear:both;padding: 0.08rem"
      >
        <span v-if="section.listType === 1 || (section.listType === 2 && section.selectPoolId)">共约{{
            totalSize
          }}件商品</span>
        <span v-else-if="section.listType === 2">共计{{ $parent.goods.length }}件商品</span>
      </div>
    </template>
  </div>
</template>
<script>
  import InfiniteLoading from "vue-infinite-loading"
  import { Spinner } from "mint-ui"
  import isEqual from "@/utils/isEqual"
  import {
    applyPageData,
    setPageLoadDone,
    getPageLoadDone,
    removePageLoadDone,
    handleUnionHrefClick
  } from "@/utils/union"

  import { siteConfig } from "@/config"
  import { transformQuery } from "@/utils/transformSort"

  const { isJdUnion = false, dragLocation = false } = siteConfig

  export default {
    name: "MyGoodsList",
    components: {
      InfiniteLoading,
      [Spinner.name]: Spinner
    },
    props: {
      section: {
        type: Object
      },
      query: {
        type: Object,
        default: () => {}
      }
    },
    data () {
      return {
        list: [],
        isLoading: false,
        params: {
          curpage: 1,
          page: 10
        },
        identifier: new Date(),
        totalSize: 0
      }
    },
    watch: {
      "section" (curr, pre) {
        if (curr.listType !== pre.listType) {
          this.reset()
          return curr
        }
        // 精确选择
        if (curr.listType === 2 && !curr.selectPoolId) {
          // 商品不同
          if (!isEqual(curr.list, pre.list)) {
            this.reset()
          }
          return curr
        }
        if (!isEqual(curr.listOptions, pre.listOptions)) {
          this.reset()
        }
        if (!isEqual(curr, pre)) {
          this.reset()
        }
        return curr
      },
      "query" (curr, pre) {
        if (isEqual(pre, curr)) {
          return
        }
        this.reset()
      }
    },
    methods: {
      reset () {
        this.makeQuery({
          curpage: 1,
          page: 10
        })
        this.list = []
        this.$emit("reset")
        if (this.section.listType === 2 && !this.section.selectPoolId) {
          this.getGoodsByIds()
        } else {
          this.identifier = new Date()
        }
      },
      // 商品无限加载
      infiniteHandler ($state) {
        if (getPageLoadDone()) {
          removePageLoadDone()
          if (this.list.length) {
            $state.loaded()
            $state.complete()
            return
          }
        }

        let params
        if (this.section.listType === 2) {
          if (this.section.selectPoolId) {
            params = {
              act: "hapi",
              op: "goodsPool",
              poolId: this.section.selectPoolId
            }
          } else {
            return
          }
        }
        if (this.section.listType === 1) {
          params = {
            act: "hapi",
            op: "goods_list"
          }
        }
        if (this.isLoading) {
          return false
        }
        this.isLoading = true
        this.$http.get("", {
          params: {
            ...params,
            ...this.params,
            ...this.query,
            // 联盟定位校验库存接口
            stock: dragLocation ? 1 : undefined
          },
          loading: false
        }).then(data => {
          this.isLoading = false
          let { goods_list: goodsList } = data["datas"]
          // 添加数据
          if (typeof goodsList !== "undefined") {
            if (goodsList.length) {
              if (this.$root.preview.inIframe) {
                goodsList = goodsList.slice(0, this.section.total || this.section.column || 2)
              }
              this.list.push(...goodsList)
              this.$emit("resource", goodsList)
            }
          }
          // 没有更多了
          if (!data["hasmore"]) {
            if (this.params.curpage === 1 && this.list.length) {
              $state.loaded()
            }
            $state.complete()
            setPageLoadDone()
          } else {
            // 更新页码
            this.params.curpage += 1
            $state.loaded()
          }
          // fox design
          if (this.$root.preview.inIframe) {
            $state.complete()
            if (data.itemCount) {
              this.totalSize = data.itemCount
              return
            }
            this.totalSize = this.params.page * data["page_total"]
          }
        }).catch(() => {
          this.isLoading = false
        })
      },
      transformQuery (obj) {
        if (!obj) {
          return {}
        }
        return {
          ...this.transformUnionQuery(obj),
          ...transformQuery(obj)
        }
      },
      transformUnionQuery (obj) {
        if (!isJdUnion) {
          return {}
        }
        const query = {}
        Object.keys(obj).forEach(val => {
          let temp = obj[val]
          if (!temp) {
            return
          }
          try {
            temp = JSON.parse(temp)
          } catch (e) {}

          if (typeof temp === "boolean") {
            if (temp) {
              query[val] = 1
            }
            return
          }
          if (Array.isArray(temp)) {
            temp.forEach((v, k) => {
              if (typeof v === "object") {
                Object.keys(v).forEach(a => {
                  query[`${val}[${a}][${k}]`] = v[a]
                })
              } else {
                query[`${val}[${k}]`] = v
              }
            })
            return
          }
          if (typeof temp === "object") {
            Object.keys(temp).forEach(v => {
              if (!temp[v]) {
                return
              }
              query[`${val}[${v}]`] = temp[v]
            })
            return
          }
          query[val] = temp
        })
        return query
      },
      makeQuery (res) {
        // 移除前一个配置的分类信息
        Object.keys(this.params).map(key => {
          if (key.indexOf("category") === 0) {
            delete this.params[key]
          }
        })
        this.params = {
          ...this.params,
          ...this.transformQuery(this.section.listOptions),
          ...res
        }
      },
      getGoodsByIds () {
        const { list = [] } = this.section
        if (list.length && list.every(val => /^\d+$/.test(val))) {
          this.$http.get("", {
            params: {
              act: "hapi",
              op: "get_goods_byId",
              ids: this.section.list.join(","),
              // 联盟定位校验库存接口
              stock: dragLocation ? 1 : undefined
            }
          }).then(res => {
            this.list = this.sliceList(res["datas"]["goods_list"])
            this.$emit("resource", this.list)
          })
        } else {
          this.list = this.sliceList(list)
          this.$emit("resource", this.list)
        }
      },
      sliceList (data) {
        return data
      },
      handleClick (event) {
        handleUnionHrefClick(event, this.$data)
      }
    },
    mounted () {
      // ids
      if (this.section.listType === 2 && !this.section.selectPoolId) {
        // 父组件 Index(n) 已经执行了获取所以good_ids的逻辑了 此处无需重复查询
        // this.getGoodsByIds()
        // 如果section.list里都是商品商品数据
        if (Array.isArray(this.section.list) &&
          this.section.list.every(val => val && typeof val === "object" && val.goods_id)) {
          this.list = this.sliceList(this.section.list)
          // this.$emit('resource', this.list)
        }
      } else {
        this.makeQuery()
      }
      // 联盟定位
      applyPageData(this.$data)
      this.$emit("resource", this.list)
      // 链接跳转
      this.$el.addEventListener("click", this.handleClick, false)
    },
    destroyed () {
      this.$el.removeEventListener("click", this.handleClick)
    }
  }
</script>
