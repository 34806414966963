<style
  lang="postcss"
  scoped
>
  .module {
    overflow: hidden;
    position: relative;
    border: 1px dashed var(--base-color);
    min-height: 0.64rem;

    & img {
      width: 100%;
      opacity: 0.8;
    }

    & .text {
      position: absolute;
      left: 0;
      top: 0;
      background: var(--base-color);
      color: #fff;
      padding: 0.1rem;
      font-size: 0.18rem;
      white-space: nowrap;
    }
  }
</style>

<template>
  <div
    class="module"
    v-if="$root.preview.inIframe"
  >
    <img
      :src="url"
      :alt="code"
    />
    <div class="text">当前为效果图，请配置</div>
  </div>
</template>
<script>

  const { templateSerial } = window._config

  export default {
    name: 'MyModulePreview',
    props: {
      code: String
    },
    computed: {
      url () {
        const basePath = '/dist/foxDesign/'
        const ext = '.jpg'
        if (['image', 'images', 'placeholder', 'imageArea'].includes(this.code)) {
          return basePath + this.code + ext
        }
        return basePath + 'template' + this.templateSerial + '/' + this.code + ext
      }
    },
    data () {
      return {
        templateSerial: window.channelTemplateSerial || templateSerial
      }
    }
  }
</script>
