<style lang="postcss">
  .confirm-box {
    background: #fff;
    width: 2.6rem;
    border-radius: 0.12rem;
    overflow: hidden;

    & .top {
      padding: 0.16rem 0 0.04rem;
      font-size: 0.14rem;
      font-weight: bold;
      color: #000;
      text-align: center;
    }

    & .body {
      padding: 0.08rem 0.08rem 0.2rem;
      text-align: center;
      color: #888;

      &:after {
        background: #eee;
      }
    }

    & .bottom {
      text-align: center;
      padding: 0;

      & button {
        box-sizing: border-box;
        padding: 0.12rem 0;
        font-size: 0.14rem;
        background: #fff;
        border: none;
        margin: 0;

        &:active {
          background: #f8f8f8;
        }

        &.cancel {
          color: #333;
        }

        &.confirm {
          color: var(--base-color);

          &:after {
            background: #eee;
          }
        }
      }
    }
  }
</style>
<template>
  <my-model-box :show="show" v-on:close="cancel" v-if="visible">
    <div class="confirm-box" slot="body">
      <div class="top" v-html="title||''"></div>
      <div class="body border-bm">{{message}}</div>
      <div class="bottom flex">
        <button @click="cancel" class="flex-1 cancel border-rt">{{btn && btn[0] || '取消'}}</button>
        <button @click="confirm" class="flex-1 confirm">{{btn && btn[1] || '确定'}}</button>
      </div>
    </div>
  </my-model-box>
</template>
<script>
  import modelBox from './Model.vue'

  export default {
    components: {
      [modelBox.name]: modelBox
    },
    props: ['title', 'btn', 'message', 'callback', 'visible'],
    data () {
      return {
        show: true
      }
    },
    methods: {
      close () {
        this.show = false
      },
      cancel () {
        if (this.callback) {
          this.callback('cancel')
        }
        this.close()
      },
      confirm () {
        if (this.callback) {
          this.callback('confirm')
        }
        this.close()
      }
    }
  }
</script>
