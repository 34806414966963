<style lang="postcss" scoped>

</style>
<template>
  <div class="scroll-x" v-if="scroll">
    <div class="scroll-body">
      <slot/>
    </div>
  </div>
  <div :style="{overflow: 'hidden'}" v-else>
    <slot/>
  </div>
</template>
<script>
  import Link from './Link'

  export default {
    name: 'myScroll',
    props: {
      scroll: {
        required: true,
        type: Boolean
      }
    },
    components: {
      [Link.name]: Link
    }
  }
</script>
