export function transformSort (sortName) {
  const query = {}
  switch (sortName) {
    case "sale_desc":
      query.key = 1
      query.order = 2
      break
    case "price_asc":
      query.key = 3
      query.order = 1
      break
    case "price_desc":
      query.key = 3
      query.order = 2
      break
    case "date_asc":
      query.key = 4
      break
    case "hot_desc":
      query.key = 2
      query.order = 2
      break
    case "random":
      query.sortName = true
      break
    default:
      break
  }
  return query
}

function generateUrlParams (arr) {
  if (!Array.isArray(arr)) {
    return {}
  }
  return arr.reduce((acc, a) => {
    let length = a.length
    const last = a[length - 1]
    const key = `gc_id_${length}`
    if (!acc[key]) {
      acc[key] = last
    } else {
      acc[key] += `,${last}`
    }
    return acc
  }, {})
}

export function transformQuery ({ sortName, ...temp }) {
  const searchOptions = {}
  searchOptions.b_id = temp.b_id || temp.brandId
  searchOptions.gc_id = temp.gc_id || temp.gcId || temp.categoryId
  searchOptions.price_from = temp.price_from || temp.priceFrom
  searchOptions.price_to = temp.price_to || temp.priceTo
  searchOptions.keyword = temp.keyword || temp.words
  searchOptions.xianshi = temp.xianshi ? 1 : undefined
  searchOptions.groupbuy = temp.groupbuy ? 1 : undefined
  searchOptions.key = temp.key
  searchOptions.order = temp.order
  searchOptions.store_id = temp.storeId?.map(val => val.value).join(",")
  if (typeof temp.categoryIds === "string" && temp.categoryIds) {
    temp.categoryIds = [temp.categoryIds]
  }
  if (Array.isArray(temp.categoryIds) && temp.categoryIds.length) {
    temp.categoryIds = temp.categoryIds.map(item => {
      if (typeof item === "string") {
        return item.split(",")
      }
      return item
    })
    delete searchOptions.gc_id
  }
  return {
    ...searchOptions,
    ...transformSort(sortName),
    ...generateUrlParams(temp.categoryIds)
  }
}