<style lang="postcss" scoped>
  .top {
    top: 80vh;
  }
  .global-service {
    background: linear-gradient(-45deg, var(--base-color-alpha-90), var(--base-color));
    line-height: 1;
    border-radius: 100%;
    width: 0.48rem;
    height: 0.48rem;
    color: #fff;
    text-align: center;
    box-shadow: 0 0 0.1rem #ccc;

    & img {
      width: 100%;
      height: 100%;
      border-radius: 100%;
    }

    & .iconfont {
      display: block;
      font-size: 0.26rem;
    }

    & span {
      font-size: 0.09rem;
    }
  }
</style>
<template>
  <my-drag class="top" v-if="isShowThirdHome && show">
    <my-link class="flex-center-center flex-column global-service" :link="thirdLink">
      <img
        :src="thirdLogo"
        :alt="thirdName"
        v-if="thirdLogo"
      />
      <span class="iconfont icon-homepage" v-else/>
      <span v-if="!thirdLogo">{{ thirdName }}</span>
    </my-link>
  </my-drag>
</template>
<script>
  import MyDrag from './Drag'
  import { MyLink } from '@/components'

  import { siteConfig } from '@/config'
  const { isShowThirdHome = false, thirdName = '商城', thirdLogo = '', thirdLink = {} } = siteConfig

  export default {
    name: 'MyHomePage',
    components: {
      MyDrag,
      MyLink
    },
    data () {
      return {
        isShowThirdHome,
        thirdName,
        thirdLogo,
        thirdLink,
        show: true
      }
    },
    created () {
      window.eventBus.$on('hideThirdHome', () => {
        this.show = false
      })
    }
  }
</script>
