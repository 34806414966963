import { preview } from "@/config"

Number.isInteger = Number.isInteger || function (value) {
  return typeof value === "number" &&
    isFinite(value) &&
    Math.floor(value) === value
}
const Cookie = require("tiny-cookie")

const VueCookie = {

  install: function (Vue) {
    Vue.prototype.$cookie = this
    Vue.cookie = this
    window.cookieEnabled = Cookie.enabled()
  },
  set: function (name, value, daysOrOptions) {
    let opts = daysOrOptions
    if (Number.isInteger(daysOrOptions)) {
      opts = { expires: daysOrOptions }
    }
    Cookie.set(name, value, opts)
    if (!preview.inIframe) {
      return
    }
    // 计算过期时间
    let expires = null
    if (opts && opts.expires) {
      if (typeof opts.expires === "number") {
        // 以天为单位
        const date = new Date()
        date.setTime(date.getTime() + opts.expires * 24 * 60 * 60 * 1000)
        expires = date
      } else if (opts.expires instanceof Date) {
        expires = opts.expires
      } else {
        expires = new Date(opts.expires)
      }
    }

    // 同步到 localStorage
    const item = {
      value: value,
      expires: expires ? expires.toISOString() : "",
      path: opts && opts.path ? opts.path : "/"
    }
    localStorage.setItem(name, JSON.stringify(item))
  },

  get: function (name) {
    if (!preview.inIframe) {
      return Cookie.get(name)
    }
    // 尝试从 localStorage 获取数据
    const storedItem = localStorage.getItem(name)
    if (storedItem) {
      const item = JSON.parse(storedItem)

      // 检查过期时间
      if (item.expires && new Date(item.expires) < new Date()) {
        localStorage.removeItem(name)
        return null
      }

      // 检查路径（简单实现，仅供示例）
      if (item.path && !this.pathMatches(item.path, location.pathname)) {
        return null
      }
      return item.value
    }

    // 如果 localStorage 中没有，尝试从 Cookie 获取数据
    return Cookie.get(name)
  },

  delete: function (name) {
    // 移除 Cookie
    Cookie.remove(name)

    // 移除 localStorage 中的数据
    localStorage.removeItem(name)
  },

  pathMatches: function (cookiePath, currentPath) {
    // 确保路径以 '/' 开头
    if (!cookiePath.startsWith("/")) {
      cookiePath = "/" + cookiePath
    }
    if (!currentPath.startsWith("/")) {
      currentPath = "/" + currentPath
    }

    // 检查是否完全匹配或是否在子路径中
    return currentPath === cookiePath || currentPath.startsWith(cookiePath === "/" ? cookiePath : (cookiePath + "/"))
  }
}

export default VueCookie