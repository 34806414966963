<style lang="postcss" scoped>
  .list {
    height: 0.2rem;
    overflow: hidden;
  }

  .cell {
    display: block;
    color: #604e40;
  }

  .icon-systemprompt {
    color: var(--base-color)
  }

  .icon-enter {
    font-size: .18rem;
    color: #ccc;
  }
</style>
<template>
  <swiper :options="swiperOption" class="list">
    <swiper-slide v-for="val in list" :key="val.id">
      <my-link
        class="flex-left-center"
        :link="goDetail(val.article_id, val['article_url'])"
      >
        <span class="iconfont icon-systemprompt"></span>
        <p class="flex-1 text-1 cell">{{val.article_title}}</p>
        <span class="iconfont icon-enter"></span>
      </my-link>
    </swiper-slide>
  </swiper>
</template>
<script>
  import 'swiper/dist/css/swiper.css'
  import { swiper, swiperSlide } from 'vue-awesome-swiper'
  import MyLink from './Link'

  export default {
    name: 'MyNews',
    components: {
      swiper,
      swiperSlide,
      MyLink
    },
    data () {
      return {
        swiperOption: {
          direction: 'vertical',
          autoplay: {
            delay: 4000,
            disableOnInteraction: true,
            stopOnLastSlide: false
          }
        },
        list: []
      }
    },
    methods: {
      initiation () {
        this.$http.get('', {
          params: {
            act: 'index',
            op: 'getgg',
            ac_id: 1
          }
        }).then(res => {
          const { article_list: arr = [] } = res['datas'] || {}
          this.list = arr || []
          if (this.list.length === 0) {
            this.$emit('change', false)
          }
        })
      },
      goDetail (id, url) {
        return url ? {
          type: 'custom',
          query: url
        } : {
          path: '/article',
          query: {
            id: id
          }
        }
      }
    },
    created () {
      this.initiation()
    }
  }
</script>
