<style lang="postcss" scoped>
  .menu {
    overflow: hidden;

    & .cell {
      float: left;

      &.c-5 {
        width: 20%;
      }

      &.c-4 {
        width: 25%;
      }

      &.c-3 {
        width: 33.3333%;
      }

      & img {
        width: 100%;
      }
    }
  }
</style>
<template>
  <div class="menu">
    <router-link class="cell" :class="cellSize()" :to="search(val)" v-for="(val, key) in list" :key="key">
      <img v-lazy="val.gc_thumb"/>
    </router-link>
  </div>
</template>
<script>
  import Link from './Link'

  export default {
    name: 'MyDefaultMenu',
    components: {
      [Link.name]: Link
    },
    data () {
      return {
        list: []
      }
    },
    methods: {
      initiation () {
        this.$http.get('', {
          params: {
            act: 'index',
            op: 'menu'
          }
        }).then(res => {
          if (res.datas.list) {
            this.list = res.datas.list
          }
        })
      },
      search (val) {
        return {
          path: '/search/list',
          query: {
            title: val.gc_name,
            gcId: val.gc_id
          }
        }
      },
      cellSize () {
        if (this.list.length <= 5) {
          return {
            'c-5': true
          }
        } else {
          return {
            ['c-' + Math.ceil(this.list.length / 2)]: true
          }
        }
      }
    },
    created () {
      this.initiation()
    }
  }
</script>
