// 倒计时
export default {
  install (Vue) {
    Vue.prototype.countDown = function (sec, step, endCb) {
      if (sec < 0) {
        return
      }
      if (typeof step !== 'function') {
        return
      }
      var d = Math.floor(sec / (3600 * 24))
      var h = Math.floor((sec % (3600 * 24)) / 3600)
      var m = Math.floor(sec % 3600 / 60)
      var s = Math.floor(sec % 60)
      var b
      var t = function (n) {
        return n > 9 ? n : ('0' + n)
      }
      var timer = setInterval(b = function () {
        s--
        if (s < 0) {
          s = 59
          m--
        }
        if (m < 0) {
          m = 59
          h--
        }
        if (h < 0) {
          clearInterval(timer)
          endCb && endCb()
          return
        }
        step([t(d), t(h), t(m), t(s)])
      }, 1000)
      b()
      return timer
    }
  }
}
