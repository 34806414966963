import Vue from 'vue'
import Alert from './Alert.vue' // 引入组件
let AlertConstructor = Vue.extend(Alert) // 返回一个“扩展实例构造器”

let AlertFun = (options = {}) => {
  let el = document.createElement('div')
  document.body.appendChild(el)
  if (window.AlertHandle) {
    window.AlertHandle.visible = false
  }
  new AlertConstructor({
    propsData: {
      ...options,
      visible: true
    }
  }).$mount(el)
}

export default AlertFun
