<style lang="postcss" scoped>
  .icon-return {
    position: absolute;
    left: 0;
    top: 0;
    width: 0.38rem;
    height: 0.44rem;
    line-height: 0.44rem;
    text-align: center;
  }

  .flag-box {
    padding-top: 0.1rem;
    color: #999;

    & .line {
      height: 1px;
      width: 0.2rem;
      background: #ccc;
      margin: 0 0.04rem;
    }

    & .active {
      color: var(--base-color);
    }
  }
</style>
<template>
  <pay-layout>
    <div slot="top">
      <div>设置支付密码</div>
      <span class="iconfont icon-return" @click="backStep"></span>
    </div>
    <div slot="money">
      <div class="flex-center-center flag-box">
        <div class="flex active" @click="reInput">
          <span class="iconfont icon-success_fill"></span>
          <span>设置密码</span>
        </div>
        <span class="line"></span>
        <div class="flex" :class="{ active: firstPwd.length === 6}">
          <span class="iconfont icon-success_fill"></span>
          <span>重复密码</span>
        </div>
        <span class="line"></span>
        <div class="flex" :class="{ active: firstPwd.length === 6 && secondPwd === firstPwd}">
          <span class="iconfont icon-success_fill"></span>
          <span>验证码</span>
        </div>
      </div>
      <div class="btn-captcha">
        <send-sms v-if="firstPwd.length === 6 && secondPwd === firstPwd"/>
      </div>
    </div>
    <transition slot="center" name="pop-in">
      <my-input-num
        v-model="firstPwd"
        key="111"
        v-if="firstPwd.length !== 6"
      />
      <my-input-num
        v-model="secondPwd"
        key="222"
        v-else-if="firstPwd.length === 6 && secondPwd !== firstPwd"
      />
      <my-input-num
        v-model="captcha"
        key="333"
        :show-num="true"
        v-else-if='firstPwd.length===6 && secondPwd === firstPwd'
      />
      <div v-else-if="saveOk">ok</div>
    </transition>
  </pay-layout>
</template>
<script>
  import PayLayout from './Layout'
  import MyInputNum from '../InputNum'
  import { Toast } from 'mint-ui'
  import SendSms from './SendSms'

  export default {
    name: 'MyScene4',
    components: {
      SendSms,
      PayLayout,
      MyInputNum
    },
    data () {
      return {
        firstPwd: '',
        secondPwd: '',
        captcha: '',
        saveOk: false
      }
    },
    watch: {
      secondPwd (curr) {
        if (curr.length === 6 && curr !== this.firstPwd) {
          Toast({
            message: '两次密码不一致'
          })
        }
      },
      captcha (curr) {
        if (curr.length === 6) {
          this.save()
        }
      }
    },
    methods: {
      backStep () {
        this.$emit('step', { type: 'back' })
        Object.assign(this.$data, {
          firstPwd: '',
          secondPwd: '',
          captcha: ''
        })
      },
      reInput () {
        this.firstPwd = ''
        this.secondPwd = ''
      },
      checkCaptcha () {
        return this.$http.post('', this.postData({
          auth_code: this.captcha
        }), {
          params: {
            act: 'member_account',
            op: 'modify_paypwd_step3'
          }
        })
      },
      save () {
        this.checkCaptcha().then(() => {
          this.$http.post('', this.postData({
            password: this.firstPwd,
            password1: this.secondPwd
          }), {
            params: {
              act: 'member_account',
              op: 'modify_paypwd_step5'
            }
          }).then(_ => {
            Toast({
              message: '设置成功！',
              position: 'bottom'
            })
            const password = this.firstPwd
            this.backStep() // 会清空this.$data, 故上一步备份password
            this.$emit('input', true)
            // 通知scene1
            window.eventBus.$emit('restPwdIsOk', password)
          })
        })
      }
    }
  }
</script>
