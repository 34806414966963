import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import direction from './module/transtionName'
import menu from './module/menu'
import loading from './module/loading'
import cart from '@/store/module/cart'
import toolBar from '@/store/module/toolBar'
import thirdPoint from '@/store/module/thirdPoint'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    direction,
    menu,
    loading,
    cart,
    toolBar,
    thirdPoint
  },
  plugins: [createPersistedState()]
})

window.__store = store

export default store
