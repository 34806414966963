import { axios } from '../plugins/axios'
import cookie from '@/plugins/cookie'
import { siteConfig, preview } from '@/config'

const { location: pos = [], geographical = false, addressType = '' } = siteConfig

function setAddr (arr) {
  cookie.set('address', JSON.stringify(arr), { path: window._cookiePath })
  window.eventBus.$emit('addressChange', true)
}

function defaultAddr () {
  if (pos && Array.isArray(pos) && pos.length) {
    // pos是一个 [p,c,a]结构的数组 而后台需要 的是 [{area_id: p}, {area_id: c}, {area_id: a}] 需临时处理供后端使用
    setAddr(pos.map(val => ({ area_id: val })))
    // 获取名称供前台使用
    axios.get('', {
      params: {
        act: 'hapi',
        op: 'location',
        area_id: pos[pos.length - 1]
      }
    }).then(res => {
      const { datas: names } = res
      const arr = pos.map((val, key) => ({ area_name: names[key], area_id: val }))
      setAddr(arr)
    })
  }
}

function position (save = true) {
  if (preview.inIframe) {
    return
  }
  if (location.protocol === 'https:' && navigator.geolocation && geographical) {
    navigator.geolocation.getCurrentPosition(res => {
      let latitude = res.coords.latitude // 纬度
      let longitude = res.coords.longitude // 经度
      // 通过经纬度获取位置
      axios.get('', {
        params: {
          act: 'hapi',
          op: 'getPosition',
          lat: latitude,
          lng: longitude,
          type: addressType
        }
      }).then(res => {
        if (save) {
          setAddr(res['datas'])
        } else {
          window.eventBus.$emit('geolocationSuccess', res.datas)
        }
      })
    }, () => {
      // 未授权
      window.eventBus.$emit('geolocationFalse')
    }, {
      enableHighAccuracy: true,
      timeout: 4e3,
      maximumAge: 6e4
    })
    return false
  } else {
    window.eventBus.$emit('geolocationFail')
  }
}

export default function getPos () {
  // 如果cookie存在 直接返回
  const addr = cookie.get('address')
  if (addr) {
    window.eventBus.$emit('addressChange', true)
    return false
  }
  // 首先是否存在配置地址
  defaultAddr()
  // 定位获取
  position()
}

export { position, setAddr }
