export default {
  methods: {
    hasPromotion (item) {
      const {
        goods_promotion_price: promotion,
        goods_price: normal,
        goods_promotion_name: promotionName,
        xianshi_flag: xiangshi,
        group_flag: group,
        akc_seckill: akcSeckill,
        akc_NPieceXPrice: akcYikoujia,
        akc_superHot: akcSuperHot,
        presell_deliverdate_str_akc: akcYugao,
        nxPriceName,
        mjPriceName
      } = item
      if (promotionName) {
        if (typeof promotionName === 'string') {
          item.goods_promotion_name = [promotionName]
        }
        if (promotion === normal) {
          item.goods_price = item.goods_marketprice;
        }
        return true
      }
      if (xiangshi) {
        item.goods_promotion_name = ['限时']
        return true
      }
      if (group) {
        item.goods_promotion_name = ['抢购']
        return true
      }
      // akc logic
      let flagName = []
      let returnFlag = false
      if (akcSeckill || akcYikoujia || akcYugao || akcSuperHot || nxPriceName || mjPriceName) {
        if (akcYugao) {
          flagName.push('预告')
        } else if (akcSeckill) {
          flagName.push('秒杀')
        } else if (akcYikoujia) {
          flagName.push('一口价')
        } else if (akcSuperHot) {
          flagName.push('爆品')
        } else if (nxPriceName) {
          flagName.push(nxPriceName)
        } else if (mjPriceName) {
          flagName.push(mjPriceName)
        }

        if (promotion === normal) {
          item.goods_price = item.goods_marketprice
        } else {
          if (item.goods_marketprice > 0) {
            item.goods_promotion_price = normal
            item.goods_price = item.goods_marketprice
          }
        }
        returnFlag = true
      }
      if (returnFlag) {
        item.goods_promotion_name = flagName
        return true
      }

      return !!(promotion && promotion * 1 && (promotion * 1 < normal * 1))
    }
  }
}
