<template>
  <div
    @touchstart="startDrag"
    @mousedown="startDrag"
    @touchmove="onDrag"
    @mousemove="onDrag"
    @mouseup="endDrag"
    @touchend="endDrag"
    class="tab-container">
    <div
      ref="wrap"
      class="mint-tab-container-wrap">
      <slot></slot>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
  .tab-container {
    overflow: hidden;
    position: relative;

    & .mint-tab-container-wrap {
      display: flex;
    }

    & .swipe-transition {
      transition: transform 300ms ease-in-out;
    }
  }
</style>
<script>
  import arrayFindIndex from 'array-find-index'

  export default {
    name: 'my-tab-container',
    props: {
      value: {},
      swipeable: Boolean
    },
    data () {
      return {
        start: { x: 0, y: 0 },
        swiping: false,
        activeItems: [],
        pageWidth: 0,
        currentActive: this.value,
        top: {}
      }
    },

    watch: {
      value (val) {
        this.currentActive = val
      },

      currentActive (val, oldValue) {
        this.$emit('input', val)
        if (!this.swipeable) {
          return
        }
        const lastIndex = arrayFindIndex(this.$children, item => item.id === oldValue)
        this.swipeLeaveTransition(lastIndex)
      }
    },

    mounted () {
      if (!this.swipeable) {
        return
      }
      this.wrap = this.$refs.wrap
      this.pageWidth = this.wrap.clientWidth || this.wrap.style.width || screen.availWidth
      this.limitWidth = this.pageWidth / 8
    },

    methods: {
      preFix () {
        let ele = document.createElement('div').style
        let arr = ['t', 'webkitT']
        for (let i = 0; i < arr.length; i++) {
          let t = arr[i] + 'ransform'
          if (t in ele) {
            return arr[i].substr(0, arr[i].length - 1)
          }
        }
        return false
      },
      prefixStyle (style) {
        if (this.preFix() === '') {
          return style
        }
        style = style.charAt(0).toUpperCase() + style.substr(1)
        return this.preFix() + style
      },
      once (el, event, fn) {
        let listener = function () {
          if (fn) {
            fn.apply(this, arguments)
          }
          el.removeEventListener(event, listener)
        }
        el.addEventListener(event, listener, false)
      },
      swipeLeaveTransition (lastIndex = 0) {
        if (typeof this.index !== 'number') {
          this.index = arrayFindIndex(this.$children, item => item.id === this.currentActive)
          this.swipeMove(-lastIndex * this.pageWidth)
        }

        setTimeout(() => {
          this.wrap.classList.add('swipe-transition')
          this.swipeMove(-this.index * this.pageWidth)
          this.once(this.wrap, this.preFix() ? 'webkitTransitionEnd' : 'transitionend', () => {
            this.wrap.classList.remove('swipe-transition')
            this.wrap.style.cssText = ''
            this.swiping = false
            // 滚动到上次位置
            this.wrap.parentNode.scrollTop = this.top[this.index] || 0
            this.index = null
          })
        }, 0)
      },

      swipeMove (offset) {
        this.wrap.style[this.prefixStyle('transform')] = `translate3d(${offset}px, 0, 0)`
        this.swiping = true
      },

      startDrag (evt) {
        if (!this.swipeable) {
          return
        }
        evt = evt.changedTouches ? evt.changedTouches[0] : evt
        this.dragging = true
        this.start.x = evt.pageX
        this.start.y = evt.pageY
        const index = arrayFindIndex(this.$children, item => item.id === this.currentActive)
        this.top[index] = this.wrap.parentNode.scrollTop
      },

      onDrag (evt) {
        if (!this.dragging) {
          return
        }
        let swiping
        const e = evt.changedTouches ? evt.changedTouches[0] : evt
        const offsetTop = e.pageY - this.start.y
        const offsetLeft = e.pageX - this.start.x
        const y = Math.abs(offsetTop)
        const x = Math.abs(offsetLeft)

        swiping = !(x < 5 || (x >= 5 && y >= x * 1.73))
        // console.log(swiping)
        // y轴滚动
        if (!swiping) {
          evt.stopPropagation()
          return
        }
        evt.preventDefault()

        const len = this.$children.length - 1
        const index = arrayFindIndex(this.$children, item => item.id === this.currentActive)
        const currentPageOffset = index * this.pageWidth
        const offset = offsetLeft - currentPageOffset
        const absOffset = Math.abs(offset)

        if (absOffset > len * this.pageWidth || (offset > 0 && offset < this.pageWidth)) {
          this.swiping = false
          return
        }

        this.offsetLeft = offsetLeft
        this.index = index
        this.swipeMove(offset)
      },

      endDrag () {
        if (!this.swiping) {
          return
        }
        this.dragging = false
        const direction = this.offsetLeft > 0 ? -1 : 1
        const isChange = Math.abs(this.offsetLeft) > this.limitWidth

        if (isChange) {
          this.index += direction
          const child = this.$children[this.index]
          if (child) {
            this.currentActive = child.id
            return
          }
        }

        this.swipeLeaveTransition()
      }
    }
  }
</script>
