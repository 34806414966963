export default {
  install (Vue, context) {
    Vue.prototype.goBack = function (url, name) {
      if (window._config.config.isKidsWant) {
        window.kidsWantUtils.back()
        return
      }
      window.history.length > 1
        ? this.$router.back()
        : this.$router.push('/')
    }.bind(context)
  }
}
