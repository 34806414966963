export const UPDATE_DIRECTION = 'UPDATE_DIRECTION'

export default {
  state: {
    direction: 'pop-in'
  },
  mutations: {
    [UPDATE_DIRECTION] (state, payload) {
      state.direction = payload.direction
    }
  }
}
