<style
  lang="postcss"
  scoped
>
  .addr-model {
    width: inherit;

    &.toolbar {
      padding-bottom: 0.54rem;
    }

    & .iconfont {
      font-size: 0.16rem;
    }

    & .top {
      line-height: 0.4rem;
      font-size: 0.13rem;
      font-weight: bold;
      text-align: center;
    }

    & .icon-close {
      position: absolute;
      right: 0.08rem;
      top: 0.1rem;
    }

    & .tab-tit {
      display: flex;
      padding-left: 0.08rem;

      & .item {
        background: none;
        border: none;
        padding: 0;
        margin: 0 0.08rem;
        line-height: 0.3rem;
        font-size: 0.115rem;

        &.active {
          color: var(--base-color);
          background-image: linear-gradient(to top, var(--base-color) 50%, transparent 50%, transparent 100%);
          background-size: auto 1px;
          background-position: bottom left;
          background-repeat: repeat-x;
        }
      }
    }

    & .tab-con {
      width: 100%;
      height: 3.5rem;
      overflow: scroll;
      overflow-x: hidden;
      -webkit-overflow-scrolling: touch;
      padding-bottom: calc(0.44rem +  env(safe-area-inset-bottom));
    }

    & .cell {
      padding: 0.08rem 0.16rem;
      line-height: 0.14rem;

      & .iconfont {
        font-size: 0.14rem;
        font-weight: bold;
        margin-left: 0.04rem;
      }

      &.active {
        color: var(--base-color);
      }
    }
  }
</style>
<template>
  <mt-popup
    v-model="isShow"
    position="bottom"
    @touchmove.prevent.stop
    style="width: inherit"
  >
    <div
      class="addr-model"
      :class="{toolbar: toolBar.show && toolBar.isNeed}"
      @touchmove.prevent.stop
    >
      <span
        class="iconfont icon-close"
        @click="$emit('close', false)"
      ></span>
      <div class="top">{{ complete ? "所在地区" : "配送至" }}</div>
      <my-geolocation v-if="geolocation" @change="$emit('close', false)"/>
      <div class="tab-tit border-bm">
        <div
          class="item"
          :class="{active: selected === key}"
          v-for="(val, key) in info"
          :key="key"
          @click="tabActive(key)"
        >
          {{ val.select ? val.select.area_name : "请选择" }}
        </div>
      </div>
      <!-- tab-container -->
      <my-tab-container
        class="tab-con"
        v-model="selected"
        swipeable
        @touchmove.prevent.stop
      >
        <mt-tab-container-item
          :id="key"
          v-for="(item, key) in info"
          :key="key"
          @touchmove.prevent.stop
        >
          <div
            class="flex-left-center cell"
            :class="{active: item.select && item.select.area_id === v.area_id}"
            v-for="(v, k) in item.list"
            :key="k"
            @click="check(key, v)"
          >
            {{ v.area_name }}
            <span
              class="iconfont icon-right"
              v-if="item.select && item.select.area_id === v.area_id"
            />
          </div>
        </mt-tab-container-item>
      </my-tab-container>
    </div>
  </mt-popup>
</template>
<script>
  import { Popup, TabContainerItem } from 'mint-ui'
  import TabSwipe from '../components/TabSwipe.vue'
  import Geolocation from '@/components/Geolocation.vue'
  import { mapState } from 'vuex'

  export default {
    name: 'my-position',
    components: {
      [Popup.name]: Popup,
      [TabSwipe.name]: TabSwipe,
      [TabContainerItem.name]: TabContainerItem,
      [Geolocation.name]: Geolocation
    },
    model: {
      prop: 'isVisible',
      event: 'close'
    },
    props: {
      isVisible: {
        type: Boolean,
        required: true
      },
      complete: {
        type: Boolean,
        default: false
      },
      pointGoods: {
        type: Boolean,
        default: false
      },
      geolocation: {
        type: Boolean,
        default: false
      },
      deep: {
        type: Number,
        default: 4
      }
    },
    data () {
      return {
        selected: 0,
        isShow: false,
        info: []
      }
    },
    computed: {
      ...mapState(['toolBar'])
    },
    watch: {
      isVisible (curr) {
        this.isShow = curr
        const body = document.querySelector('body')
        const contain = document.querySelector('.contain')
        if (curr) {
          body.style.cssText = 'overflow:hidden;min-height: auto;'
          contain && contain.addEventListener('touchmove', this.stop, false)
        } else {
          body.removeAttribute('style')
          contain && contain.removeEventListener('touchmove', this.stop, false)
        }
      },
      isShow (curr) {
        if (!curr) {
          this.$emit('close', false)
        }
      }
    },
    methods: {
      stop (e) {
        e.stopPropagation()
        e.preventDefault()
      },
      getList (areaId) {
        return this.$http.get('', {
          params: {
            act: 'area',
            op: 'area_list',
            area_id: areaId,
            isMarcoPolo: this.$route.query.isMarcoPolo
          }
        })
      },
      province () {
        this.getList().then(res => {
          this.info.push({
            select: null,
            list: this.areaSort(res.datas.area_list)
          })
        })
      },
      areaSort (areaList) {
        if (!areaList.length) {
          return areaList
        }
        let sortField
        if (areaList.some(({ area_sort }) => area_sort !== undefined)) {
          sortField = 'area_sort'
        } else {
          sortField = 'area_id'
        }
        return areaList.sort((a, b) => a[sortField] * 1 - b[sortField])
      },
      check (key, item) {
        this.info[key].select = item
        if (key >= this.deep) {
          this.selectEnd()
          return
        }
        if (this.pointGoods) {
          this.selectEnd()
          return
        }
        if (!this.complete && key >= 2) {
          this.selectEnd()
          return
        }
        this.getList(item.area_id).then(({ datas }) => {
          const { area_list: areaList } = datas
          if (areaList.length) {
            this.info.splice(key + 1, 1, {
              select: null,
              list: this.areaSort(areaList)
            })
            setTimeout(() => {
              this.selected = key + 1
            }, 100)
          } else {
            // selectEnd
            this.selectEnd()
          }
        })
      },
      selectEnd () {
        let arr = this.info.map(val => {
          return val.select
        })
        // 地址存储到cookie
        this.$cookie.set('address', JSON.stringify(arr), { expires: 1000, path: window._cookiePath })
        window.eventBus.$emit('addressChange')
        this.$emit('close', false)
        this.$emit('change', arr)
      },
      tabActive (key) {
        this.info = this.info.slice(0, key + 1)
        this.info[key].select = null
        setTimeout(() => {
          this.selected = key
        }, 100)
      }
    },
    mounted () {
      this.province()
    }
  }
</script>
