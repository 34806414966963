<template>
  <a
    href="javascript:void(0);"
    @click="goJd"
    v-if="unionUrl"
  >
    <slot/>
  </a>
  <div
    v-else-if="useClickAction"
    @click="handleClick"
  >
    <slot/>
  </div>
  <div
    v-else-if="useJingjiniao"
    @click="handleJingjiniao"
  >
    <slot/>
  </div>
  <div
    v-else-if="useMessageBox"
    @click="openMessageBox"
  >
    <slot/>
  </div>
  <router-link
    :to="url"
    v-else-if="useRouteJump"
  >
    <slot/>
  </router-link>
  <a
    :href="link.query"
    v-else
  >
    <slot/>
  </a>
</template>
<script>
  import Prompt from "./Prompt"
  import MessageBox from "@/components/MessageBox"
  import deepCopy from "../utils/deepCopy"
  import { Toast } from "mint-ui"
  import { siteConfig } from "@/config"
  import { Confirm } from "@/components/index"

  const { useShortLink = false } = siteConfig

  export default {
    name: "MyLink",
    props: {
      link: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        url: {},
        unionUrl: ""
      }
    },
    computed: {
      useClickAction () {
        return this.url.type === "click"
      },
      useMessageBox () {
        return this.url.type === "messageBox"
      },
      useRouteJump () {
        return this.url.type !== "custom" || (this.url.type === "custom" && typeof this.url.query === "object")
      },
      useJingjiniao () {
        return this.url.type === "jingjiniao"
      }
    },
    methods: {
      openMessageBox () {
        MessageBox(this.url.query)
      },
      handleClick () {
        const { act, op, parameter, userInput, ...model } = this.url.query
        const params = {}

        if (Array.isArray(parameter)) {
          parameter.forEach(({ key, value }) => {
            params[key] = value
          })
        }
        if (Array.isArray(userInput)) {
          Prompt({
            title: "请填写以下信息",
            userInput
          }).then(res => {
            this.clickLogic({ act, op, params: { ...params, ...res } })
          })
          return
        }
        if (model.showMessageBox) {
          Confirm({
            message: model.messageBoxContent,
            title: model.messageBoxTitle || "",
            btn: [model.messageBoxBtn1 || "取消", model.messageBoxBtn2 || "确定"],
            callback: type => {
              if (type === "confirm") {
                this.clickLogic({ act, op, params })
              }
            }
          })
        } else {
          this.clickLogic({ act, op, params })
        }
      },
      clickLogic (options) {
        const { act, op, params } = options
        if (this.loading) {
          return
        }
        this.loading = true
        this.$http.post("", this.postData(params), {
          params: {
            act,
            op
          }
        }).then(res => {
          try {
            this.loading = false
            if (typeof res.datas === "string") {
              Toast({
                message: res.datas,
                position: "bottom"
              })
              return
            }
            const { url, message, model } = res.datas
            if (model && typeof model === "object") {
              Confirm({
                message: model.message,
                title: model.title || "",
                btn: [model.btn1 || "取消", model.btn2 || "确定"],
                callback: type => {
                  if (type === "confirm" && model.url) {
                    if (typeof model.url === "object") {
                      this.$router.push(model.url)
                    } else {
                      window.location.href = decodeURIComponent(model.url)
                    }
                  }
                }
              })
            }
            if (message) {
              Toast({
                message,
                position: "bottom"
              })
            }
            if (url.indexOf("http") === 0) {
              window.location.href = decodeURIComponent(url)
            }
          } catch (e) {
            throw new Error("url error")
          }
        }).catch(e => {
          this.loading = false
        })
      },
      handleJingjiniao () {
        const { act, op, ...params } = this.url.query
        this.clickLogic({ act, op, params })
      },
      goJd () {
        if (!useShortLink) {
          location.href = this.unionUrl
          return
        }
        if (this.loading) {
          return
        }
        this.loading = true
        this.$http.get("", {
          params: {
            act: "hapi",
            op: "union",
            method: "promotionLink",
            sku: this.url.item.goods_id
          }
        }).then(({ datas }) => {
          const { shortURL } = datas
          if (/^https?:\/\//.test(shortURL)) {
            window.location = shortURL
            this.loading = false
          }
        }).catch(() => {
          window.location = this.unionUrl
          this.loading = false
        })
      }
    },
    created () {
      this.url = deepCopy(this.link)

      if (this.url.type === "application" || this.url.type === "site") {
        this.url.path = this.url.query
        if (this.url.params) {
          this.url.query = { ...this.url.params }
          delete this.url.params
        } else {
          delete this.url.query
        }
      } else if (this.url.type === "custom") {
        if (typeof this.url.query === "object") {
          delete this.url.query.preview
          this.url = this.url.query
        }
      } else if (this.url.type === "unionSearch") {
        this.url.query = { u: this.url.query }
      } else if (this.url.type === "itemInfo") {
        if (!this.url.item.union_url) {
          this.url = { path: "/detail", query: { id: this.url.item.goods_id } }
        } else {
          if (!useShortLink) {
            this.unionUrl = this.url.item.union_url
          } else {
            this.unionUrl = this.url.item.goods_id
          }
        }
      }
    }
  }
</script>
