<style lang="postcss" scoped>
  .coin {
    color: var(--base-color);
    line-height: 1;
    display: table;
    font-size: 0.12rem;
    font-family: NUM_Regular, -apple-system;

    &.lighter {
      font-family: NUM_Light, -apple-system;
    }

    &.bolder {
      font-family: NUM_Bold, -apple-system;
    }

    & .currency {
      font-size: 0.12rem;
      padding: 0 0.01rem 0.01rem 0;
    }

    & .dot {
      font-size: 0.14rem;
      line-height: 1;
    }

    & .integer {
      font-size: inherit;
    }

    & .decimal {
      font-size: 0.12rem;
    }
  }
</style>
<template>
  <div class="coin" ref="coin" :class="{lighter:fontBold===0, bolder:fontBold===2}" v-if="show">
    <template v-if="priceRule.type === 'cash'">
      <span class="currency">{{currencySymbol[currency]}}</span>
      <span class="integer">{{integer}}</span>
      <span class="dot">.</span>
      <span class="decimal">{{decimal}}</span>
    </template>
    <template v-else-if="priceRule.type === 'point'">
      <span class="integer">{{integer * priceRule.ratio + Math.round(decimal * priceRule.ratio / 100)}}</span>
      <span class="currency">{{priceRule.unit}}</span>
    </template>
    <template v-else-if="priceRule.type === 'pointAndCash'">
      <template v-if="priceRule.rule === 'percent'">
        <span class="integer">{{percentPoint[0]}}</span>
        <span class="dot" v-if="percentPoint[1]">.</span>
        <span class="decimal" v-if="percentPoint[1]">{{percentPoint[1]}}</span>
        <span class="currency">{{priceRule.unit}}</span>
        <span class="add"> + </span>
        <span class="currency">¥</span>
        <span class="integer">{{percentCash[0]}}</span>
        <span class="dot" v-if="percentCash[1]">.</span>
        <span class="decimal" v-if="percentCash[1]">{{percentCash[1]}}</span>
      </template>
      <template v-else-if="priceRule.rule === 'pointFixed'">
        <span class="integer">{{pointFixed[0]}}</span>
        <span class="dot" v-if="pointFixed[1]">.</span>
        <span class="decimal" v-if="pointFixed[1]">{{pointFixed[1]}}</span>
        <span class="currency">{{priceRule.unit}}</span>
        <span class="add"> + </span>
        <span class="currency">¥</span>
        <span class="integer">{{cashSurplus[0]}}</span>
        <span class="dot" v-if="cashSurplus[1]">.</span>
        <span class="decimal" v-if="cashSurplus[1]">{{cashSurplus[1]}}</span>
      </template>
      <template v-else-if="priceRule.rule === 'cashFixed'">
        <span class="currency">¥</span>
        <span class="integer">{{cashFixed[0]}}</span>
        <span class="dot" v-if="cashFixed[1]">.</span>
        <span class="decimal" v-if="cashFixed[1]">{{cashFixed[1]}}</span>
        <span class="add"> + </span>
        <span class="integer">{{pointSurplus[0]}}</span>
        <span class="dot" v-if="pointSurplus[1]">.</span>
        <span class="decimal" v-if="pointSurplus[1]">{{pointSurplus[1]}}</span>
        <span class="currency">{{priceRule.unit}}</span>
      </template>
    </template>
  </div>
</template>
<script>
  import { siteConfig } from '../config'

  const {
    hideDel = false,
    priceRule = {
      type: 'cash'
    }
  } = siteConfig

  export default {
    name: 'MyCoin',
    props: {
      coin: {
        required: true
      }
    },
    data () {
      return {
        fontBold: 1,
        show: true,
        priceRule,
        currency: 0,
        rate: 1,
        currencySymbol: ['¥', '$']
      }
    },
    computed: {
      integer () {
        let arr = this.c()
        return arr[0]
      },
      decimal () {
        let arr = this.c()
        return arr[1]
      },
      percentPoint () {
        let p = (this.coin * priceRule.ratio * priceRule.percentPoint / 100).toFixed(2)
        return p.split('.').map(val => this.d(val))
      },
      percentCash () {
        let p = (this.coin * (100 - priceRule.percentPoint) / 100).toFixed(2)
        return p.split('.').map(val => this.d(val))
      },
      pointFixed () {
        return priceRule.pointFixed.split('.').map(val => this.d(val))
      },
      cashSurplus () {
        let pointEqualCash = (priceRule.pointFixed / priceRule.ratio).toString()
        let indexDot = pointEqualCash.indexOf('.')
        if (indexDot !== -1) {
          pointEqualCash = pointEqualCash.slice(0, indexDot + 3)
        }
        let p = this.coin - pointEqualCash
        return p.toString().split('.').map(val => this.d(val))
      },
      cashFixed () {
        return priceRule.cashFixed.split('.').map(val => this.d(val))
      },
      pointSurplus () {
        let point = (this.coin - priceRule.cashFixed) * priceRule.ratio
        return point.toString().split('.').map(val => this.d(val))
      }
    },
    methods: {
      c () {
        let price = this.coin
        if (this.currency > 0) {
          price = price / this.rate
        }
        let c = (price && (price * 1).toFixed(2)) || '0.00'
        return c.split('.')
      },
      d (val) {
        return val.replace('00', '').replace(/^([1-9])0*$/, function ($1, $2) {
          return $2
        })
      }
    },
    created () {
      this.$nextTick(() => {
        let ele = this.$refs.coin
        let completeStyle = window.getComputedStyle(ele, null)
        let fontWeight = completeStyle.getPropertyValue('font-weight')
        let textDecoration = completeStyle.getPropertyValue('text-decoration')
        // 判断字体粗细
        if (fontWeight >= 700 || ['bold', 'bolder'].includes(fontWeight)) {
          this.fontBold = 2
        } else if (fontWeight >= 300 || fontWeight === 'normal') {
          this.fontBold = 1
        } else {
          this.fontBold = 0
        }
        // 为划线价
        if (textDecoration.indexOf('line-through') !== -1 && hideDel) {
          // 是否隐藏划线价
          this.show = false
        }
      })
      // 货币类型
      this.currency = Number(this.$cookie.get('currency') || 0)
      this.rate = Number(this.$cookie.get('usRate') || 1)
    }
  }
</script>
