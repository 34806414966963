import Vue from 'vue'
import Prompt from './Prompt.vue' // 引入组件
const PromptConstructor = Vue.extend(Prompt) // 返回一个“扩展实例构造器”

const PromptFun = (options = {}) => {
  let el = document.createElement('div')
  document.body.appendChild(el)
  if (window.PromptHandle) {
    window.PromptHandle.visible = false
  }
  return new Promise((resolve, reject) => {
    new PromptConstructor({
      propsData: {
        ...options,
        visible: true,
        resolve,
        reject
      }
    }).$mount(el)
  })
}

export default PromptFun
