<style
  lang="postcss"
  scoped
>
  .wakeup-app {
    width: 100%;
    height: 0.44rem;
    background: #232326;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0 0.08rem;
    overflow: hidden;
    position: relative;
    z-index: 99;

    & .close {
      display: inline-block;
      width: 0.16rem;
      height: 0.16rem;
      background: url(https://hzwimspic-1251601690.picsh.myqcloud.com/e992b970-47f8-11ea-abb2-95e202fd2c70_size_48x48) no-repeat;
      -webkit-background-size: 100% 100%;
      background-size: 100% 100%;
      overflow: hidden;
    }

    & .logo {
      width: 0.28rem;
      height: 0.28rem;
      margin-left: 0.08rem;
    }

    & .tips {
      margin-left: 0.13rem;

      & > p  {
        font-size: 0.12rem;
        line-height: 0.18rem;
        max-width: 1.3rem;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      & .title {
        color: #FFFFFF;
      }

      & .desc {
        color: #999999;
      }
    }

    & .open-app {
      display: block;
      width: 0.68rem;
      height: 0.24rem;
      background: url(https://hzwimspic-1251601690.picsh.myqcloud.com/d56f2ad0-47fa-11ea-8ad0-bdf1e8a4b95c_size_206x72) no-repeat;
      -webkit-background-size: 100% 100%;
      background-size: 100% 100%;
      overflow: hidden;
    }
  }

</style>
<template>
  <div class="flex-center-center wakeup-app" v-if="show">
    <span class="close" @click="visible=!visible"></span>
    <img
      src="https://hzwimspic-1251601690.picsh.myqcloud.com/2abd4fa0-47f9-11ea-abb2-95e202fd2c70_size_90x90"
      class="logo"
    >
    <div class="flex-1 tips">
      <p class="title">打开孩子王APP</p>
      <p class="desc">体验服饰奥莱频道</p>
    </div>
    <div class="open-app" @click="open"></div>
  </div>
</template>
<script>
  // const ua = window.navigator.userAgent
  // const isXiaoMi = ua.indexOf('XiaoMi') !== -1
  // const isVivo = ua.indexOf('VivoBrowser') !== -1

  export default {
    name: 'WakeupApp',
    data () {
      return {
        visible: true,
        env: false,
        rightPage: false,
        showPage: ['index.html', 'venue', 'detail']
      }
    },
    methods: {
      open () {
        location.href = 'https://w.cekid.com/download-app/v3.html'
      }
    },
    computed: {
      show () {
        return this.visible && this.env && this.rightPage && !this.$root.app.preview.inIframe
      }
    },
    watch: {
      '$root.app.$route' (curr) {
        this.rightPage = this.showPage.includes(this.$root.app.$route.name)
      },
      '$root.app.nativeBar' (curr) {
        this.env = !curr.isApp && !curr.isMiniProgram
      }
    }
  }
</script>
