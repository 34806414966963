<style lang="postcss" scoped>
  .drag {
    position: fixed;
    right: 0.1rem;
    top: calc(100vh - 2.6rem);
    z-index: 9999;
  }
</style>
<template>
  <div
    class="drag"
    :style="{top: top + 'px'}"
    @touchstart="handleStart"
    @mousedown="handleStart"
    @touchmove="handleMove"
    @mousemove="handleMove"
    @mouseup="handleEnd"
    @touchend="handleEnd"
    v-if="$root.preview.inIframe === false"
  >
    <slot/>
  </div>
</template>
<script>
  export default {
    name: 'MyDrag',
    data () {
      return {
        top: '2rem',
        start: {
          y: 0
        },
        height: 0
      }
    },
    methods: {
      handleStart (evt) {
        // evt.stopPropagation()
        // evt.preventDefault()
        const e = evt.changedTouches ? evt.changedTouches[0] : evt
        this.dragging = true
        this.startY = e.clientY
      },
      handleMove (evt) {
        evt.stopPropagation()
        evt.preventDefault()
        if (!this.dragging) {
          return
        }
        const e = evt.changedTouches ? evt.changedTouches[0] : evt
        const moveSize = e.clientY - this.startY
        const maxHeight = window.screen.availHeight - this.height
        const minHeight = 0
        if (this.top <= minHeight) {
          if (moveSize < 0) {
            this.top = minHeight
          } else {
            this.top += moveSize
          }
        } else if (this.top >= maxHeight) {
          if (moveSize > 0) {
            this.top = maxHeight
          } else {
            this.top += moveSize
          }
        } else {
          this.top += moveSize
        }
        this.startY = e.clientY
      },
      handleEnd (evt) {
        // evt.stopPropagation()
        // evt.preventDefault()
        if (!this.swiping) {
          return
        }
        this.dragging = false
      }
    },
    mounted () {
      this.$nextTick(() => {
        if (this.$el.nodeType === 8) {
          return
        }
        const { top, height } = this.$el.getBoundingClientRect()
        this.top = top
        this.height = height
      })
    }
  }
</script>
