<style
  lang="postcss"
  scoped
>
  .search {
    background: #fff;
    height: 0.28rem;
    border-radius: 0.14rem;
    color: #999;

    & .icon-jd {
      font-size: 0.18rem;
      font-weight: bold;
      color: var(--base-color);
      padding: 0 0.08rem;
    }

    & .icon-search {
      padding: 0 0.06rem;
      font-size: 0.16rem;
    }
  }
</style>
<template>
  <router-link
    :to="{path: '/search/input', query: {words: curr}}"
    class="flex-center-center search btn-radius"
  >
    <span
      class="iconfont icon-jd"
      v-if="showJD"
    ></span>
    <div
      class="flex-1 flex-center-center"
      style="overflow:hidden;"
      :class="{'border-lt': showJD}"
    >
      <span
        class="iconfont icon-search"
        v-if="showSearchIcon"
      ></span>
      <p class="flex-1 text-1 hot">{{ curr }}</p>
      <slot name="button"/>
    </div>
  </router-link>
</template>
<script>
  const { modules = [] } = window._config
  const { list = [] } = modules.filter(val => val.code === 'header')[0] || {}

  const placeholder = '请输入关键字'

  export default {
    name: 'MySearch',
    props: {
      showJD: {
        type: Boolean,
        default: false
      },
      keywords: {
        type: [Array, String],
        default: () => list
      },
      showSearchIcon: {
        type: Boolean,
        default: true
      }
    },
    data () {
      return {
        curr: placeholder,
        timer: null,
        i: 0
      }
    },
    methods: {
      placeHolder (query) {
        const { words, keyword } = query
        if (words || keyword) {
          this.curr = decodeURIComponent(words || keyword)
          return false
        } else {
          this.curr = placeholder
        }
      },
      initiation () {
        if (this.placeHolder(this.$route.query) === false) {
          return
        }
        if (this.$route.path === '/search/list') {
          return
        }
        if (typeof this.keywords === 'string') {
          this.curr = this.keywords
          return
        }
        if (this.keywords && this.keywords.length) {
          this.timer = setInterval(this.cb = () => {
            if (this.i >= this.keywords.length) {
              this.i = 0
            }
            this.curr = this.keywords[this.i]
            this.i++
            // 通知父组件关键词变化
            this.$emit('change', this.curr)
          }, 3000)
          this.cb()
        }
      }
    },
    created () {
      this.initiation()
    },
    activated () {
      this.initiation()
    },
    beforeDestroy () {
      clearInterval(this.timer)
    },
    watch: {
      '$route.query' (curr) {
        this.placeHolder(curr)
      }
    }
  }
</script>
