<style lang="postcss">
  .pay-model {
    height: 70%;
    min-height: initial;
    overflow: hidden;
    &.toolbar {
      padding-bottom: 0.54rem;
    }

    & .icon-close {
      padding: 0.12rem 0.08rem !important;
      font-size: 0.18rem !important;
    }
  }
</style>
<template>
  <my-model-bottom
    class="pay-model"
    :closeOnClickModal="false"
    :class="{toolbar: toolBar.show && toolBar.isNeed}"
    :show="show"
    @close="close"
  >
    <transition :name="animation">
      <keep-alive>
        <my-bind-card
          @step="setSteps"
          v-if="currStep === 'bindCard'"
        />
        <my-scene4
          @step="setSteps"
          v-if="currStep === 'setPwd'"
        />
        <my-scene3
          @change="setValue"
          @step="setSteps"
          v-else-if="currStep === 'inputPwd'"
        />
        <my-scene2
          v-model="isUseYue"
          title="预存款支付"
          label="使用预存款"
          @step="setSteps"
          v-else-if="currStep === 'yueConfig' && !doNotUsePdPay"
        />
        <my-scene2
          v-model="isUseJifen"
          title="积分抵扣"
          label="使用积分"
          @step="setSteps"
          v-else-if="currStep === 'jifenConfig'"
        />
        <my-scene2
          v-model="isUseThirdPoint"
          title="积分抵扣"
          label="使用积分"
          @step="setSteps"
          v-else-if="currStep === 'thirdPointConfig'"
        />
        <my-scene1
          :virtual="virtual"
          :paySn="pay_sn"
          :isUseJifen="isUseJifen"
          :isUseYue="isUseYue"
          :isUseThirdPoint="isUseThirdPoint"
          :isShow="show"
          :pwd="pwd"
          :doNotUsePdPay="doNotUsePdPay"
          :immediately="immediately"
          @step="setSteps"
          @valChange="setValue"
          v-else
        />
      </keep-alive>
    </transition>
  </my-model-bottom>
</template>
<script>
  import MyModelBottom from '../ModelBottom.vue'
  import MyScene1 from './Scene1'
  import MyScene2 from './Scene2'
  import MyScene3 from './Scene3'
  import { Confirm } from '../index'
  import MyScene4 from './Scene4'
  import MyBindCard from '@/components/PayModel/BindCard'
  import { mapState } from 'vuex'

  export default {
    name: 'MyPayModel',
    components: {
      MyBindCard,
      MyScene4,
      MyScene3,
      MyScene2,
      MyScene1,
      MyModelBottom
    },
    model: {
      prop: 'isShow',
      event: 'close'
    },
    props: {
      virtual: {
        type: Boolean,
        default: false
      },
      pay_sn: {
        type: String,
        required: true
      },
      isShow: {
        type: Boolean,
        required: true
      },
      doNotUsePdPay: {
        type: Boolean,
        default: false
      },
      immediately: {
        type: Boolean,
        default: false
      }
    },
    watch: {
      isShow (cur) {
        this.show = cur
      }
    },
    data () {
      return {
        show: false,
        currShowCloseHandler: null,
        steps: [],
        animation: '',
        isUseJifen: false,
        isUseYue: false,
        isUseThirdPoint: false,
        pwd: ''
      }
    },
    computed: {
      currStep () {
        return this.steps.length ? this.steps[this.steps.length - 1] : ''
      },
      ...mapState(['toolBar'])
    },
    methods: {
      close (val) {
        if (this.currShowCloseHandler) {
          return
        }
        Confirm({
          title: '提示',
          message: '确定要取消支付么？',
          btn: ['再想想看', '果断取消'],
          callback: type => {
            if (type === 'confirm') {
              this.$emit('close', val)
              Object.assign(this.$data, {
                currShowCloseHandler: null,
                steps: [],
                animation: '',
                isUseJifen: false,
                isUseYue: false,
                isUseThirdPoint: false
              })
              this.currShowCloseHandler = true
              setTimeout(() => {
                this.currShowCloseHandler = false
              }, 10)
            }
          }
        })
      },
      setValue (options) {
        Object.assign(this.$data, options)
        if (this.doNotUsePdPay) {
          this.isUseYue = false
        }
      },
      setSteps (val) {
        if (val.type === 'add') {
          this.steps.push(val.name)
          this.animation = 'pop-in'
        } else {
          this.steps.pop()
          this.animation = 'pop-out'
        }
      }
    }
  }
</script>
