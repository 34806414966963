<style lang="postcss">
  .edit-num {
    border-radius: 0.04rem;
    overflow: hidden;

    &:before {
      border-radius: 0.08rem;
    }

    & .btn {
      width: 0.24rem;
      height: 0.24rem;
      line-height: 0.24rem;
      font-size: 0.16rem;
      text-align: center;
      font-weight: lighter;
      display: block;
      background: transparent;
      color: #b5b5b5;
      padding: 0;
      margin: 0;

      &.disable {
        background: #eee;
        color: #aaa;
      }
    }

    & .input {
      width: 0.36rem;
    }

    & input {
      height: 0.24rem;
      line-height: 1;
      padding: 0 0.04rem;
      width: 100%;
      box-sizing: border-box;
      text-align: center;
      -webkit-appearance: none;
      border: none;
      outline: none;
      font-family: NUM_Regular;
      font-size: 0.14rem;
    }
  }
</style>
<template>
  <div class="flex-center-center border edit-num">
    <button
      class="btn btn-reduce"
      :class="{disable: disableLeft}"
      @click="reduce()"
    >-
    </button>
    <div class="input border-lt">
      <input
        type="number"
        pattern="[0-9]*"
        name="num"
        ref="num"
        min="0"
        max="99"
        :value="value"
        placeholder="1"
        @blur="blur"
        @input="edit"
        :readonly="disableLeft && disableRight"
      />
    </div>
    <button
      class="btn btn-add border-lt"
      :class="{disable: disableRight}"
      @click="add()"
    >+
    </button>
  </div>
</template>
<script>
  export default {
    name: 'my-edit-num',
    props: {
      value: {
        type: [Number, String],
        required: true
      },
      max: {
        type: [Number, String],
        default: 99
      },
      min: {
        type: [Number, String],
        default: 1
      }
    },
    data () {
      return {
        emitData: this.value,
        disableLeft: false,
        disableRight: false
      }
    },
    watch: {
      emitData (curr) {
        this.disableLeft = curr <= this.min
        this.disableRight = curr >= this.max
        return curr
      },
      value (curr) {
        this.emitData = this.value
      }
    },
    methods: {
      edit (event) {
        if (event.target.value) {
          this.emitData = event.target.value * 1
          if (this.emitData < this.min) {
            this.emitData = this.min
          } else if (this.emitData > this.max) {
            this.emitData = this.max
          }
          event.target.value = this.emitData
          this.$emit('input', this.emitData)
        }
      },
      add () {
        if (this.emitData < this.max) {
          this.emitData = this.emitData * 1 + 1
          this.$emit('input', this.emitData)
        }
      },
      reduce () {
        if (this.value > this.min) {
          this.emitData = this.emitData * 1 - 1
          this.$emit('input', this.emitData)
        }
      },
      blur (event) {
        if (!event.target.value) {
          this.emitData = this.min
          event.target.value = this.emitData
          this.$emit('input', this.emitData)
          return
        }
        if (event.target.value * 1 < this.min) {
          this.emitData = this.min
          this.$emit('input', this.emitData)
        }
      }
    },
    created () {
      this.disableLeft = this.emitData <= this.min
      this.disableRight = this.emitData >= this.max
    }
  }
</script>
