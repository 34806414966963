<style lang="postcss" scoped>
  .icon-return {
    position: absolute;
    left: 0;
    top: 0;
    width: 0.38rem;
    height: 0.44rem;
    line-height: 0.44rem;
    text-align: center;
  }

  .rest-pwd {
    color: #999;
    text-align: right;
    padding: 0.08rem 0.3rem 0;

    & .iconfont {
      font-size: 0.16rem;
    }
  }
</style>
<template>
  <pay-layout>
    <div slot="top">
      <div>输入支付密码</div>
      <span class="iconfont icon-return" @click="backStep"></span>
    </div>
    <div class="flex-right-center rest-pwd" @click="nextStep" slot="money" v-if="!disableReset">
      <span>不记得密码？点击这里重置</span>
      <span class="iconfont icon-enter"></span>
    </div>
    <div v-else>&nbsp;</div>
    <my-input-num
      slot="center"
      v-model="currPwd"
    />
  </pay-layout>
</template>
<script>
  import PayLayout from './Layout'
  import MyInputNum from '../InputNum'
  import { Toast } from 'mint-ui'

  export default {
    name: 'MyScene3',
    components: {
      PayLayout,
      MyInputNum
    },
    data () {
      return {
        currPwd: '',
        disableReset: false,
        checkPwd: true
      }
    },
    watch: {
      currPwd (newVal) {
        // 验证密码是否正确
        if (newVal.length !== 6) {
          return
        }
        if (!this.checkPwd) {
          this.$emit('change', { pwd: newVal })
          window.eventBus.$emit('pwdIsOk', newVal)
          return
        }
        this.$http.post('', this.postData({
          password: newVal
        }), {
          params: {
            act: 'member_buy',
            op: 'check_pd_pwd'
          }
        }).then(res => {
          if (res['datas'] * 1 === 1) {
            this.$emit('change', { pwd: newVal })
            // 通知Scene1密码输入正确
            window.eventBus.$emit('pwdIsOk', newVal)
          } else {
            Toast({
              message: '验证出错，请联系客服',
              position: 'bottom'
            })
          }
        }).catch(() => {
          this.currPwd = ''
        })
      }
    },
    methods: {
      backStep () {
        this.$emit('step', { type: 'back' })
      },
      nextStep () {
        if (this.disableReset) return false
        this.$emit('step', { type: 'add', name: 'setPwd' })
      }
    },
    created () {
      window.eventBus.$on('inputPwdEvent', obj => {
        Object.assign(this.$data, obj)
      })
    }
  }
</script>
