import Vue from "vue"
import cookie from "@/plugins/cookie"

// _config配置
if (!window._config) {
  throw new Error("window._config must be an object!")
}
// 站点名称
if (window._config.title) {
  window._config.name = window._config.title
}
// 站点配置
const { config: siteConfig = {}, path: cookiePath = "/", domain, keyExpireDays = 365, acceptOrigin } = window._config
const BSAE_URL = domain + "/"
const AJAX_BASE_URL = BSAE_URL + "mobile/index.php"
const EVENTBUS = new Vue()
const IS_WEIXIN = /MicroMessenger/g.test(navigator.userAgent)
const IS_MINIPROGRAM = window["__wxjs_environment"] === "miniprogram"
const isYQQF = /[a-z]{3}\d{12}[a-z]\d{1,3}[a-z]{2}/.test(BSAE_URL)
// cookie存储路径
if (process.env.NODE_ENV !== "production") {
  window._cookiePath = "/"
} else {
  window._cookiePath = domain.replace(/https?:\/\/([\w-]+\.)+\w+/, "") + cookiePath
}

const KEY = "key"
let getKey = () => {
  return cookie.get(KEY) || localStorage.getItem(window._cookiePath + KEY) || undefined
}
let setKey = (val) => {
  cookie.set(KEY, val, { expires: keyExpireDays, path: window._cookiePath })
  localStorage.setItem(window._cookiePath + KEY, val)
  // 更新购物车
  window.__store.dispatch("addCartBatchAsync").then(r => {})
}

let delKey = () => {
  cookie.delete(KEY)
  localStorage.removeItem(window._cookiePath + KEY)
}

const makeDomain = (arr, port, protocol = "http://") => {
  return `${protocol}${arr.join(".")}${port}`
}

const isCrossDomain = (domain) => {
  const acceptDomain = [
    makeDomain([192, 168, 1, 115], ":8080"),
    makeDomain([192, 168, 1, 115], ":8000"),
    makeDomain([47, 93, 215, 184], ":8081"),
    makeDomain(['fox', 'giftgine', 'cn'], '')
  ]
  if (acceptOrigin) {
    acceptDomain.push(acceptOrigin)
  }
  return acceptDomain.includes(domain)
}

// fox design template preview
const preview = {
  state: /preview=1/.test(window.location.search),
  inIframe: (() => {
    if (!document.referrer) {
      return false
    }
    const matchs = /^https?:\/\/[\w-]+(\.[\w-]+)+(:\d+)?/.exec(document.referrer)
    return window.self !== window.top && isCrossDomain(matchs[0]) && window.name !== 'thorny-bird'
  })()
}

export {
  BSAE_URL,
  AJAX_BASE_URL,
  siteConfig,
  EVENTBUS,
  IS_WEIXIN,
  getKey,
  setKey,
  delKey,
  isYQQF,
  IS_MINIPROGRAM,
  preview,
  isCrossDomain
}

// 禁用微信字体调整
(function () {
  if (!IS_WEIXIN) {
    return
  }

  if (typeof window["WeixinJSBridge"] === "object" && typeof window["WeixinJSBridge"].invoke === "function") {
    handleFontSize()
  } else {
    document.addEventListener("WeixinJSBridgeReady", handleFontSize, false)
  }

  function handleFontSize () {
    // 设置网页字体为默认大小
    window["WeixinJSBridge"].invoke("setFontSizeCallback", { "fontSize": 0 })
    // 重写设置网页字体大小的事件
    window["WeixinJSBridge"].on("menu:setfont", function () {
      window["WeixinJSBridge"].invoke("setFontSizeCallback", { "fontSize": 0 })
    })
  }
})()

// 字体兼容宽屏幕
;(function () {
  const handleResize = () => {
    const hRw = window.innerHeight / window.innerWidth
    if (hRw < 1.5) {
      const baseFontSize = 0.277778 * window.innerHeight / 1.5
      document.documentElement.style.fontSize = baseFontSize + "px"
    } else {
      document.documentElement.style.cssText = ''
    }
  }
  handleResize()
  window.addEventListener("resize", handleResize, false)
})()