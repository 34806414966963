<style lang="postcss">
  .payment-list {

    & .tit {
      padding: 0.08rem;
      color: #aaa;
    }

    & .arrow {
      display: block;
      width: 0.08rem;
      height: 0.08rem;
      position: absolute;
      transform-origin: center;
      background: #fff;
      z-index: 999999;
      transform: translate(0.2rem, 0.035rem) rotate(-45deg);

      &:before,
      &:after {
        background: #ddd;
      }
    }

    & .pay-include {
      padding-left: 0.2rem;
    }

    & .pay-item {
      padding: 0.12rem 0.12rem 0.12rem 0;

      &:after {
        background: #eee;
      }

      & .iconfont {
        margin-right: 0.08rem;
      }
    }

    & .icon-alipay {
      color: #009fe9;
    }

    & .icon-wxpay,
    & .icon-lzf {
      color: #1a9204;
    }

    & [class*=icon-sds] {
      &:before {
        content: '\e637';
      }
    }

    & .icon-sdsapppay {
      &:before {
        display: none;
      }

      background: url("../../assets/sds.png") center center no-repeat;
      width: 0.28rem;
      height: 0.28rem;
      background-size: 100% auto;
      box-shadow: 0 0 1px #ccc;
      border-radius: 0.04rem;
    }

    & .icon-lzf {
      &:before {
        content: "\e619";
      }
    }

    & .icon-lzf2 {
      width: 0.18rem;
      height: 0.18rem;
      display: block;
      background: url("../../assets/lzf2.jpg") center center no-repeat;
      background-size: 140% auto;
    }

    & .note {
      color: #aaa;
      text-indent: 0.08rem;
      margin-top: 0.03rem;
    }
  }
</style>
<template>
  <div class="payment-list">
    <div class="border-bm tit">
      <span>{{ otherText ? '其他' : '' }}支付方式</span>
      <span class="border-tp border-rt arrow"></span>
    </div>
    <div class="pay-include">
      <div
        class="pay-item border-bm"
        v-for="val in payInfo.payment_list"
        :key="val.payment_code"
      >
        <div class="flex-between">
          <div class="flex-center-center">
            <span
              class="iconfont"
              :class="iconFilter(val.payment_code)"
            ></span>
            <span>{{ val.payment_name }}</span>
          </div>
          <label>
            <input
              type="radio"
              :value="val.payment_code"
              v-model="payType"
              @change="valChange"
            />
          </label>
        </div>
        <p
          class="note"
          v-if="val.description"
        >{{ val.description }}
        </p>
        <template v-else>
          <p
            class="note"
            v-if="payInfo.member_fd * 1"
          >
            {{ val.payment_name.toString().replace('支付', '') }}可用数：{{ payInfo.member_fd }}
          </p>
          <p
            class="note"
            v-if="payInfo.card_pd * 1"
          >
            <span style="padding-left: 0.28rem"></span>
            {{ val.payment_name.toString().replace('支付', '') }}可用余额：{{ payInfo.card_pd }}
          </p>
        </template>
        <sds-card
          :cardsNo="payInfo.cardsNo"
          :defaultCardNo="payInfo.defaultCardNo"
          :cardPd="payInfo.card_pd"
          :paymentCode="val.payment_code"
          :payType="payType"
          @input="cardChange"
        />
      </div>
    </div>
  </div>
</template>
<script>
  import SdsCard from './SdsCard'

  export default {
    name: 'PaymentList',
    components: { SdsCard },
    props: {
      value: {
        type: String,
        required: true,
        default: ''
      },
      otherText: Boolean,
      payInfo: {
        type: Object,
        required: true,
        default: () => {
          return {
            payment_list: [],
            cardsNo: [],
            member_fd: ''
          }
        }
      }
    },
    data () {
      return {
        payType: ''
      }
    },
    watch: {
      value (curr) {
        this.payType = curr
      }
    },
    methods: {
      valChange () {
        this.$emit('input', this.payType)
      },
      iconFilter (code) {
        let preFix = 'icon-'
        if (/(weixin|cpay|wxpay|wx_)/.test(code)) {
          return preFix + 'wxpay'
        } else {
          return preFix + code
        }
      },
      cardChange (options) {
        this.$emit('extra', options)
      }
    },
    created () {
      this.payType = this.value
    }
  }
</script>
