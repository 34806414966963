<style
  lang="postcss"
  scoped
>
  .count-down {
    font-size: 0;
    line-height: 0;
    text-align: right;
    margin: 0.04rem 0 0;
  }

  .count-down em {
    display: inline-block;
    margin: 0 0.03rem;
    font-size: 0.12rem;
    font-style: normal;

  }

  .count-down em.day {
    vertical-align: -0.1rem;
  }

  .count-down span {
    display: inline-block;
    line-height: 1;
    width: 0.18rem;
    font-size: 0.11rem;
    padding: 0.02rem 0;
    background: #fff;
    border-radius: 2px;
    color: var(--base-color);
    text-align: center;
  }
</style>
<template>
  <div class="count-down">
    <template v-if="day">
      <span>{{ day }}</span>
      <em class="days">天</em>
    </template>
    <span>{{ hour }}</span>
    <em class="hour">:</em>
    <span>{{ min }}</span>
    <em class="minute">:</em>
    <span>{{ sec }}</span>
  </div>
</template>
<script>
  export default {
    name: "CountDown",
    props: {
      second: {
        type: [Number, String],
        required: true
      },
      callback: {
        type: Function
      }
    },
    data () {
      return {
        day: 0,
        hour: 0,
        min: 0,
        sec: 0
      }
    },
    methods: {
      initiation () {
        this.timer = this.countDown(this.second, arr => {
          this.day = arr[0] * 1 ? arr[0] : 0
          this.hour = arr[1]
          this.min = arr[2]
          this.sec = arr[3]
        }, () => {
          // this.$emit('timeEnd', true)
          if (this.callback) {
            this.callback()
            return
          }
          location.reload()
        })
      }
    },
    mounted () {
      this.initiation()
    },
    beforeDestroy () {
      clearInterval(this.timer)
    },
  }
</script>
