<style lang="postcss" scoped>
  .pwd {
    margin-bottom: env(safe-area-inset-bottom);
    width: 100%;
    height: 100%;

    &[class*=pop-in] {
      position: absolute;
    }

    & .inp {
      height: 0.44rem;
      margin: 0.14rem 0.3rem 0;
      width: calc(100% - 0.6rem);

      &:before {
        border-radius: 0.12rem;
      }

      & .item {
        height: 100%;
        position: relative;
        line-height: 0.44rem;
        text-align: center;
        font-size: 0.16rem;

        &.active {
          &:before {
            content: '';
            width: 0.08rem;
            height: 0.08rem;
            background: #000;
            display: inline-block;
            border-radius: 100%;
            position: absolute;
            left: 50%;
            top: 50%;
            margin: -0.04rem 0 0 -0.04rem;
          }

          & span {
            display: none;
          }
        }

        &.showNum {
          &:before {
            display: none;
          }

          & span {
            display: block;
          }
        }
      }
    }

    & .key {
      overflow: hidden;
      background: #f8f8f8;
      width: 100%;

      & .item {
        float: left;
        width: 33.3333%;
        height: 0.6rem;
        line-height: 0.6rem;
        text-align: center;
        font-size: 0.2rem;
        color: #000;

        &:active {
          background: #fdfdfd;
        }
      }

      & .del,
      & .sure {
        font-size: 0.14rem;
      }

      & .sure {
        background: var(--base-color);
        color: #fff;

        &:active {
          background: red;
        }
      }
    }
  }
</style>
<template>
  <div class="flex-between flex-column pwd">
    <ul class="border flex-center-center inp">
      <li
        class="flex-1 item"
        :class="{' border-rt': val<6, 'active': val < pwd.length + 1, showNum}"
        :key="val"
        v-for="val in arr1"
      >
        <span>{{pwd[val-1] !== undefined ? pwd[val-1] : ''}}</span>
      </li>
    </ul>
    <ul class="key">
      <li
        class="item border-rt border-bm"
        v-for="val in arr2"
        :key="val"
        @touchstart="inp(val % 10)"
      >
        <span>{{val % 10}}</span>
      </li>
      <li class="item border-rt border-bm del" @click="del">退格</li>
      <li class="item border-rt border-bm sure" @click="sure">确定</li>
    </ul>
  </div>
</template>
<script>
  import { Toast } from 'mint-ui'

  export default {
    name: 'InputNum',
    props: {
      value: String,
      showNum: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        arr1: [1, 2, 3, 4, 5, 6],
        arr2: [1, 2, 3, 4, 5, 6, 7, 8, 9, 0],
        pwd: []
      }
    },
    watch: {
      value (curr) {
        if (curr === this.pwd.join('')) {
          return
        }
        this.pwd = curr.split('')
      },
      pwd (curr) {
        if (curr.length === 6) {
          this.sure()
        }
      }
    },
    methods: {
      inp (key) {
        if (this.pwd.length >= 6) {
          return
        }
        this.pwd.push(key)
      },
      del () {
        this.pwd.pop()
      },
      sure () {
        if (this.pwd.length < 6) {
          Toast({
            message: '密码格式不正确',
            position: 'bottom'
          })
          return
        }
        this.$emit('input', this.pwd.join(''))
      }
    }
  }
</script>
