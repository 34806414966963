<style lang="postcss" scoped>
  .global-service {
    background: linear-gradient(-45deg, var(--base-color-alpha-90), var(--base-color));
    line-height: 1;
    border-radius: 100%;
    width: 0.44rem;
    height: 0.44rem;
    color: #fff;
    text-align: center;
    box-shadow: 0 0 0.1rem #5a5a5a;
    display: block;

    & .iconfont {
      display: block;
      font-size: 0.2rem;
      padding-top: 0.04rem;
    }

    & span {
      font-size: 0.09rem;
    }
  }
</style>
<template>
  <my-drag v-if="servicePhone">
    <a :href="'tel:' + telNumber" class="global-service" v-if="telNumber">
      <span class="iconfont icon-phone"></span>
      <span>客服</span>
    </a>
  </my-drag>
</template>
<script>
  import MyDrag from './Drag'
  import { siteConfig } from '@/config'
  const { servicePhone = false, servicePhoneNumber = '' } = siteConfig

  export default {
    name: 'MyPhone',
    components: {
      MyDrag
    },
    data () {
      return {
        telNumber: '',
        servicePhone
      }
    },
    methods: {
      getTelNumber () {
        if (!servicePhone) return false
        if (servicePhoneNumber) {
          this.telNumber = servicePhoneNumber
          this.telNumberBackup = servicePhoneNumber
          return false
        }
        this.$http.get('', {
          params: {
            act: 'hapi',
            op: 'phoneNumber'
          }
        }).then(res => {
          if (res['datas'].toString().match(/^[\d-]+$/g) && this.$route.path !== '/cart') {
            this.telNumber = res['datas']
          }
          this.telNumberBackup = res['datas']
        }).catch(() => {
          this.$http.xhrNum--
        })
      }
    },
    created () {
      this.$nextTick(() => {
        this.getTelNumber()
        this.$router.afterEach((to) => {
          if (to.path === '/cart') {
            this.telNumber = ''
          } else {
            this.telNumber = this.telNumberBackup
          }
        })
      })
    }
  }
</script>
