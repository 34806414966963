<style
  lang="postcss"
  scoped
>
  .member {
    display: block;
    line-height: 0.44rem;
  }

  .logo {
    width: 0.36rem;
    height: 0.36rem;
    margin: 0 0.02rem 0 0.1rem;
    line-height: 0.36rem;
    &.border-lt {
      &:after {
        left: -0.1rem;
        height: 50%;
        top: 25%;
      }
    }
    & img {
      width: 100%;
      object-fit: cover;
      display: inline;
    }
  }

</style>
<template>
  <my-header
    :noLeft="international || (header.hideLocation && !header.showBack)"
    :noRight="header.hideRightIcon"
    :transparent="transparent"
    :showName="header.showName"
    v-if="$root.preview.inIframe ? true :  header.visible"
    data-style-name="box"
    :style="{...styles, ...formatStyle(userStyle.box)}"
  >
    <div slot="left"  class="flex-center-center back" v-if="header.showBack">
      <span
        class="iconfont icon-return"
        @click="goBack"
      >
      </span>
    </div>
    <Location
      slot="left"
      v-if="!header.hideLocation"
    />
    <div
      class="logo"
      :class="{'border-lt': !(international || header.hideLocation)}"
      slot="logo"
      v-if="header.logo"
    >
      <img
        :src="header.logo"
        :alt="name"
      >
    </div>
    <div slot="siteName">{{ name }}</div>
    <router-link
      to="/member"
      class="member"
      slot="right"
    >
      <span class="iconfont icon-people"></span>
    </router-link>
  </my-header>
</template>
<script>
  import Header from './Header'
  import Location from './Location'
  import { siteConfig } from '../config'
  import header from "@/components/Header.vue"

  const { international = false } = siteConfig

  const { modules = [], name } = window._config

  export default {
    name: 'NormalHeader',
    components: {
      Location,
      [Header.name]: Header
    },
    props: {
      transparent: {
        type: Boolean,
        default: false
      },
      header: {
        type: Object,
        default: function () {
          return modules.filter(val => val.code === 'header')[0] || {}
        }
      }
    },
    data () {
      return {
        international,
        name
      }
    },
    computed: {
      styles () {
        const temp = {
          position: this.header.noFixed ? 'absolute' : 'fixed'
        }
        if (this.$root.preview.inIframe && this.header.visible === false) {
          temp.filter = 'grayscale(1)'
          temp.opacity = 0.4
        }
        return temp
      },
      userStyle () {
        return this.header.style || {}
      }
    },
    methods: {
      goBack () {
        this.$router.go(-1)
      }
    },
    mounted () {
      // console.log(this.header)
      if (this.$root.preview.inIframe ) {
        const div = document.createElement('div')
        div.style.cssText = 'height: 0.44rem;width: 100%;'
        this.$el.parentNode.insertBefore(div, this.$el)
      }
    }
  }
</script>
