export default {
  data () {
    return {
      customStyles: {
        width: ''
      }
    }
  },
  methods: {
    styles () {
      this.customStyles.width = document.body.clientWidth + 'px'
    }
  },
  mounted () {
    this.styles()
    window.addEventListener('resize', this.styles, false)
  },
    beforeDestroy () {
        window.removeEventListener('resize', this.styles, false)
    }
}