<style lang="postcss" scoped>
  .captcha {
    height: 0.36rem;
    width: auto;
    margin-left: 0.08rem;
  }
</style>
<template>
  <img :src="captchaImg" class="captcha" alt="cpatcha" @click="loadSeccode"/>
</template>
<script>
  import { AJAX_BASE_URL } from '@/config'

  export default {
    name: 'CaptchaImg',
    data () {
      return {
        codekey: '',
        captchaImg: ''
      }
    },
    methods: {
      loadSeccode () {
        this.$http.get('', {
          params: {
            act: 'seccode',
            op: 'makecodekey'
          }
        }).then(res => {
          this.codekey = res['datas'].codekey
          this.captchaImg = AJAX_BASE_URL + '?act=seccode&op=makecode&k=' + this.codekey + '&t=' + Math.random()
          this.$emit('input', this.codekey)
        })
      }
    },
    created () {
      this.loadSeccode()
    }
  }
</script>
