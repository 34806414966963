<style lang="postcss" scoped>
  .model-box {
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 99999;
    user-select: none;
  }

  .model-bg {
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }

  .model-contain {
    position: absolute;
    z-index: 10;
    left: 50%;
    top: 50%;
    transform-origin: center;
    transform: translate(-50%, -50%);
  }

  .fade-enter {
    opacity: 0;
  }

  .fade-enter-active {
    transition: opacity .3s ease;
  }

  .zoom-enter {
    transform: translate(-50%, -50%) scale(0);
  }

  .zoom-enter-active {
    transition: transform .3s cubic-bezier(0.215, 0.610, 0.355, 1.000);
  }

  .zoom-enter-to {
    transform: translate(-50%, -50%) scale(1);
  }
</style>
<template>
  <div class="model-box" @touchmove.prevent v-if="show" :style="customStyles">
    <transition name="fade" appear>
      <div class="model-bg" @click="close"></div>
    </transition>
    <transition :name="bodyAnimate" appear>
      <slot  v-if="noContain"/>
      <div class="model-contain" v-else>
        <slot name="body"></slot>
      </div>
    </transition>
  </div>
</template>
<script>
  import fixedWidth from "@/mixins/fixedWidth"

  export default {
    name: 'my-model-box',
    mixins: [fixedWidth],
    props: {
      show: {
        type: Boolean,
        required: true
      },
      bodyAnimate: {
        type: String,
        required: false,
        default: 'zoom'
      },
      noContain: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      close () {
        this.$emit('close')
      }
    }
  }
</script>
