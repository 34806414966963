import { axios as $http } from "@/plugins/axios"
export const SET_THIRD_POINT = "SET_THIRD_POINT"

export const INIT_THIRD_POINT = "INIT_THIRD_POINT"

export const SET_POINT_LOGIN = 'SET_POINT_LOGIN'

export const GET_THIRD_POINT = 'GET_THIRD_POINT'

export default {
  state: {
    point: {
      label: '剩余额度',
      value: '0.00',
      before: '',
      after: ''
    },
    login: false
  },
  mutations: {
    [SET_THIRD_POINT] (state, payload) {
      state.point = Object.assign({}, state.point, payload)
    },
    [INIT_THIRD_POINT] (state) {
      state.point = {}
    },
    [SET_POINT_LOGIN] (state, payload) {
      state.login = payload
    }
  },
  actions: {
    [GET_THIRD_POINT] ({commit}, payload) {
      $http.get("", {
        params: {
          act: "member_index",
          op: "getPoint"
        },
        showLoginForm: false,
        loading: false
      }).then(res => {
        commit(SET_THIRD_POINT, {
          ...payload,
          value: res.datas,
        })
        commit(SET_POINT_LOGIN, true)
      }).catch((e) => {
        if (e.login === "0") {
          commit(SET_POINT_LOGIN, false)
        }
        commit(INIT_THIRD_POINT)
      })
    }
  }
}
